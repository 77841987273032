import imgLogo001 from './../contents/img/logo-001.png';
import imgLogo002 from './../contents/img/logo-002.png';

export const LayoutParams = {
    colors: {
        corDoTemaPrincipal: '#0B333C',
        corSecundaria: '#34bfa3',
        corDoTextoPadrao: '#34bfa3',
        fundoCinza: 'rgb(222, 226, 230)',
    },
    imgHomeLogo: imgLogo002,
    imgLogoLogin: imgLogo002,
    imgReportLogo: imgLogo001,
};
