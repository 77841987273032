import React, { Component } from 'react';
import HomeEmpresaMedTrackComMapa from './Empresa/HomeEmpresaMedTrackComMapa';
import HomeEmpresaMedTrackSemMapa from './Empresa/HomeEmpresaMedTrackSemMapa';
import HomeTecnometrics from './Tecnometrics/HomeTecnometrics';

export default class HomeView extends Component {
   render() {
      return (
         <React.Fragment>
            {this.props.sessionManager.isUsuarioTecnometrics() && (
               <HomeTecnometrics
                  api={this.props.api}
                  lang={this.props.lang}
                  sessionManager={this.props.sessionManager}
               />
            )}
            {this.props.sessionManager.isContratoMedTrackComMapa() && (
               <HomeEmpresaMedTrackComMapa
                  api={this.props.api}
                  lang={this.props.lang}
                  sessionManager={this.props.sessionManager}
               />
            )}
            {this.props.sessionManager.isContratoMedTrackSemMapa() && (
               <HomeEmpresaMedTrackSemMapa
                  api={this.props.api}
                  lang={this.props.lang}
                  sessionManager={this.props.sessionManager}
               />
            )}
         </React.Fragment>
      );
   }
}
