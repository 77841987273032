import React, { Component, Fragment } from 'react';
import { Row, Col, Form, ButtonGroup, InputGroup, Modal, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faTimesCircle, faSave } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle, faSearch, faAngleDown, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import SessionManager from './../models/SessionManager';
import { LayoutParams } from './../config/LayoutParams';
import { buildQueryString, isNumeric, prepareSearchable } from '../utils/Functions';
import { showError, showInfo, showConfirm } from '../components/Messages';
import Button from '../components/Button';
import '../contents/css/formulario-padrao.css';
import '../contents/css/cabecalho-tabela-formulario-padrao.css';
import '../contents/css/tabela-formulario-padrao.css';
import IconButton from '../components/IconButton';

let timer = 0;
let delay = 200;
let prevent = false;
let mostrarCodigo = false;

export default class FormularioPadrao extends Component {
   constructor(props) {
      super(props);
      const {
         renderizarFiltros,
         getFiltro,
         getTitulosDaTabela,
         getDadosDaTabela,
         renderizarFormulario,
         getObjetoDeDados,
         antesDeInserir,
         aposInserir,
         antesDeEditar,
         aposEditar,
         antesDeSalvar,
         antesDeExcluir,
         definirValoresPadrao,
         getAcoesDaTabela,
      } = props;

      this.setState = props.setFormState;
      this.getState = props.getFormState;

      this.renderizarFiltros = renderizarFiltros ? renderizarFiltros : this.renderizarFiltros;
      this.getFiltro = getFiltro ? getFiltro : this.getFiltro;
      this.getTitulosDaTabela = getTitulosDaTabela ? getTitulosDaTabela : this.getTitulosDaTabela;
      this.getDadosDaTabela = getDadosDaTabela ? getDadosDaTabela : this.getDadosDaTabela;
      this.renderizarFormulario = renderizarFormulario ? renderizarFormulario : this.renderizarFormulario;
      this.getObjetoDeDados = getObjetoDeDados ? getObjetoDeDados : this.getObjetoDeDados;
      this.definirValoresPadrao = definirValoresPadrao ? definirValoresPadrao : this.definirValoresPadrao;

      this.antesDeInserir = antesDeInserir;
      this.aposInserir = aposInserir;
      this.antesDeEditar = antesDeEditar;
      this.aposEditar = aposEditar;
      this.antesDeSalvar = antesDeSalvar;
      this.antesDeExcluir = antesDeExcluir;
      this.getAcoesDaTabela = getAcoesDaTabela;
   }

   componentDidMount() {
      try {
         if (this.props.ref) {
            this.props.ref(this);
         }

         //let clientHeight = document.getElementById('formularioPadrao').clientHeight;
         //let clientHeight = 800;

         let sessionManager = new SessionManager();
         let permissoes = this.props.permissoes;

         this.setState({
            itemSelecionado: null,
            itens: [],
            vazio: false,
            consultou: false,
            navegando: true,
            incluindo: false,
            alterando: false,
            itemVazio: JSON.stringify(this.props.itemVazio),
            filtro: {},
            botaoSalvarHabilitado: true,
            quantidadeDeDados: 0,
            podeAvancar: false,
            podeVoltar: false,
            podeConsultar: permissoes ? sessionManager.temAcessoARotina(permissoes[0]) : false,
            podeIncluir: permissoes ? sessionManager.temAcessoARotina(permissoes[1]) : false,
            podeAlterar: permissoes ? sessionManager.temAcessoARotina(permissoes[2]) : false,
            podeExcluir: permissoes ? sessionManager.temAcessoARotina(permissoes[3]) : false,
            mostrarFormulario: true,
            ordenacao: this.props.ordenacaoPadrao,
            //tamanhoDaPagina: Math.trunc(clientHeight / 32),
            tamanhoDaPagina: 30,
         })
            .then(() => {
               let searchText = null;
               if (this.props.select) {
                  searchText = this.props.select.getSearchText();
               }
               let filtro = this.getState().filtro;
               filtro.texto = searchText;
               this.setState({ filtro: filtro, mounted: true })
                  .then(() => {
                     if (this.props.apenasInserir) {
                        this.inserirClick();
                     } else if (this.props.apenasAlterar) {
                        this.props.api
                           .getAll(this.props.url + '?id=' + this.props.id.toString())
                           .then((data) => {
                              let item = data.items[0];
                              if (item) {
                                 this.editarClick(item);
                              } else {
                                 showError(
                                    this.props.lang.formularioPadrao.mensagens.registroNaoLocalizadoParaAlteracao
                                 );
                              }
                           })
                           .catch((e) => {
                              console.error(e);
                           });
                     } else {
                        this.setState({ navegando: true });
                        this.filtrar();
                     }
                  })
                  .catch((e) => {
                     console.error(e);
                  });
            })
            .catch((e) => {
               console.error(e);
            });
      } catch (e) {
         console.error(e);
      }
   }

   /*
        Esse formulário padrão é um componente renderizado na tela.
        Para utilizado implemente os métodos a seguir e passe eles como propriedade no componente.
        Por exemplo:

        <FormularioPadrao 
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.getObjetoDeDados}
        />      
    */

   getFiltro = (filtro) => {
      // Pode ser sobrescrito caso seja necessário ter mais algum filtro na tela.
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = prepareSearchable(texto);
         }
      }
      return result;
   };

   getTitulosDaTabela = () => {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de string com os títulos para a tabela
   };
   getDadosDaTabela = (item) => {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de valores a ser usado na linha da tabela.
      // Este método é chamado passando registro por registro.
   };
   renderizarFormulario = () => {
      // * OBGRIGATÓRIO
      // Implemente esse método para retornar o formulário usado para inclusão e alteração de dados.
   };
   getObjetoDeDados = () => {
      // * OBGRIGATÓRIO
      // Implemente esse método para montar o objeto a ser usado no POST e PUT. O retorno deve ser uma Promise.
   };
   antesDeInserir = () => {
      // Implemente esse método para executar alguma ação antes de mudar a tela para o modo de inclusão.
   };
   aposInserir = () => {
      // Implemente esse método para executar alguma imediatamente após mudar a tela para o modo de inclusão.
   };
   antesDeEditar = () => {
      // Implemente esse método para executar alguma ação antes de mudar a tela para o modo de alteração.
   };
   aposEditar = () => {
      // Implemente esse método para executar alguma imediatamente após mudar a tela para o modo de alteração.
   };
   antesDeSalvar = () => {
      // Implemente esse método para executar alguma ação antes salvar.
   };
   antesDeExcluir = () => {
      // Implemente esse método para executar alguma ação antes exlcuir.
   };

   atualizarFormulario = () => {
      this.setState(
         {
            mostrarFormulario: false,
         },
         () => {
            this.setState({ mostrarFormulario: true });
         }
      );
   };

   onRowClick = (item) => {
      if (this.props.select) {
         this.props.select.aoSelecionar(item);
      }
      if (this.props.form) {
         this.props.form.aoSelecionar(item);
      }
   };

   doDoubleClickAction = (item) => {
      if (this.props.select || this.props.form || this.props.apenasInserir) {
         return;
      }
      this.editarClick(item);
   };

   handleClick = (item) => {
      let self = this;
      timer = setTimeout(function () {
         if (!prevent) {
            self.onRowClick(item);
         }
         prevent = false;
      }, delay);
   };

   handleDoubleClick = (item) => {
      clearTimeout(timer);
      prevent = true;
      this.doDoubleClickAction(item);
   };

   getItemVazio = () => {
      return JSON.parse(this.getState().itemVazio);
   };

   definirValoresPadrao = (item) => {
      return item;
   };

   formConsultaSubmit = (event) => {
      event.preventDefault();
      this.filtrar();
   };

   isMobile = () => {
      return window.screen.width <= 600;
   };

   filtrar = () => {
      return new Promise((resolve, reject) => {
         this.setState(
            {
               itens: [],
               quantidadeDeDados: null,
               quantidadeTotalDeDados: null,
               vazio: true,
               carregando: true,
            },
            () => {
               let query = '';
               let filtro = this.getState().filtro;

               if (filtro && filtro.id) {
                  query = '?id=' + filtro.id.toString;
               } else {
                  var orderBy = this.getState().ordenacao;
                  query = buildQueryString(this.getState().tamanhoDaPagina, null, orderBy, this.getFiltro(filtro));
               }

               this.props.api
                  .getAll((this.props.fastUrl ? this.props.fastUrl : this.props.url) + query)
                  .then((data) => {
                     this.setState({
                        itens: data.items,
                        quantidadeDeDados: data.pageSize,
                        quantidadeTotalDeDados: data.count,
                        podeAvancar: data.count >= this.getState().tamanhoDaPagina,
                        podeCarregarTodos: data.count >= this.getState().tamanhoDaPagina,
                        vazio: !data || data.count === 0,
                        carregando: false,
                     });
                     resolve();
                  })
                  .catch((e) => {
                     console.error(e);
                     reject();
                  })
                  .finally(() => {
                     this.setState({
                        carregando: false,
                     });
                  });
            }
         );
      });
   };

   navegar = (opcao) => {
      let query = '';
      let state = this.getState();
      var orderBy = state.ordenacao;

      let filtro = this.getState().filtro;

      if (filtro && filtro.id) {
         query = '?id=' + filtro.id.toString;
      } else {
         query = buildQueryString(
            this.getState().tamanhoDaPagina,
            state.itens.length.toString(),
            orderBy,
            this.getFiltro(filtro)
         );

         if (opcao === 1) {
            query = buildQueryString(
               this.getState().tamanhoDaPagina,
               state.itens.length.toString(),
               orderBy,
               this.getFiltro(filtro)
            );
         } else {
            query = buildQueryString(null, null, orderBy, this.getFiltro(filtro));
            state.itens = [];
         }
      }

      this.setState(
         {
            quantidadeDeDados: null,
            quantidadeTotalDeDados: null,
         },
         () => {
            this.props.api
               .getAll((this.props.fastUrl ? this.props.fastUrl : this.props.url) + query)
               .then((data) => {
                  state.itens.push(...data.items);
                  this.setState({
                     itens: state.itens,
                     quantidadeDeDados: state.itens.length,
                     quantidadeTotalDeDados: data.count,
                     podeAvancar: data.count > state.itens.length,
                     podeCarregarTodos: data.count > state.itens.length,
                     vazio: !data || data.count === 0,
                  });
               })
               .catch((e) => console.error(e));
         }
      );
   };

   limparFiltro = (sender) => {
      let filtro = sender.getState().filtro;
      filtro.texto = null;
      sender.setState({ filtro: filtro });
      if (sender.textoFiltro) {
         sender.textoFiltro.value = null;
         sender.filtrar();
      }
   };

   renderizarFiltros = (sender) => {
      return this.props.id
         ? null
         : !this.props.esconderFiltro && (
         <Form.Group style={{ margin: 0 }}>
            <InputGroup>
               {sender.getState().filtro.texto && (
                       <InputGroup.Prepend>
                     <InputGroup.Text style={{ padding: '0px 6px 0px 6px' }}>
                        <div
                           id={'btnLimpar'}
                           style={{
                              border: 0,
                              outline: 'transparent',
                              width: 25,
                              cursor: 'pointer',
                              color: LayoutParams.colors.corSecundaria,
                              fontSize: 22,
                              marginTop: -3,
                           }}
                           tabIndex={-1}
                           title={this.props.lang.formularioPadrao.limpar}
                                onClick={() => this.limparFiltro(sender)}
                        >
                           x
                        </div>
                     </InputGroup.Text>
                  </InputGroup.Prepend>
               )}

               <Form.Control
                  ref={(c) => {
                     this.textoFiltro = c;
                     if (c) {
                        c.focus();
                        if (this.props.select) {
                           let searchText = this.props.select.getSearchText();
                           if (searchText) {
                              c.selectionStart = searchText.length;
                              c.selectionEnd = searchText.length;
                           }
                        }
                     }
                  }}
                  type='text'
                  placeholder={`${this.props.lang.filtrar}...`}
                  defaultValue={sender.getState().filtro.texto}
                  onChange={(e) => {
                     let filtro = sender.getState().filtro;
                     filtro.texto = prepareSearchable(e.target.value);
                     sender.setState({ filtro: filtro });
                  }}
                  style={{ outline: 'none', boxShadow: 'none', borderColor: '#ced4da', height: 33, fontSize: 13 }}
               />
               <InputGroup.Append style={{ cursor: 'pointer' }} onClick={this.filtrar}>
                  <InputGroup.Text style={{ padding: '1px 6px 1px 6px' }}>
                     <React.Fragment>
                        <IconButton
                           style={{
                              fontSize: 22,
                              paddingTop: 2,
                              color: LayoutParams.colors.corSecundaria,
                           }}
                           icon={faSearch}
                        />
                     </React.Fragment>
                  </InputGroup.Text>
               </InputGroup.Append>
            </InputGroup>
         </Form.Group>
      );
   };

   inserirClick = () => {
      let fnInserir = () => {
         var item = this.getItemVazio();
         if (this.definirValoresPadrao) {
            item = this.definirValoresPadrao(item);
         }
         this.setState({ itemSelecionado: item }, () => {
            this.setState({ navegando: false, alterando: false, incluindo: true }, () =>
               this.aposInserir ? this.aposInserir(this) : null
            );
         });
      };

      if (this.antesDeInserir) {
         this.antesDeInserir(this).then(() => {
            fnInserir();
         });
      } else {
         fnInserir();
      }
   };

   editarClick = (clickedItem) => {
      this.getItem(clickedItem.id).then((result) => {
         if (!result) {
            showError(this.props.lang.formularioPadrao.registroNaoLocalizadoParaAlteracao);
            return;
         }
         let item = JSON.parse(JSON.stringify(result));
         if (this.antesDeEditar) {
            this.antesDeEditar(this, item).then(() => {
               this.setState({ itemSelecionado: item, versaoAnterior: item }, () => {
                  this.setState({ navegando: false, alterando: true, incluindo: false }, () =>
                     this.aposEditar ? this.aposEditar(this) : null
                  );
               });
            });
         } else {
            this.setState({ itemSelecionado: item, versaoAnterior: item }, () =>
               this.setState({ navegando: false, alterando: true, incluindo: false }, () =>
                  this.aposEditar ? this.aposEditar(this) : null
               )
            );
         }
      });
   };

   getItem = (id) => {
      return new Promise((resolve, reject) => {
         this.props.api
            .get(this.props.url + '?id=' + id.toString())
            .then((data) => {
               if (data.items.length > 1) {
                  showError(this.props.lang.formularioPadrao.mensagens.maisDeUmItemEncontradoParaOFiltroAplicado);
                  reject(this.props.lang.formularioPadrao.mensagens.maisDeUmItemEncontradoParaOFiltroAplicado);
               } else {
                  resolve(data.items[0]);
               }
            })
            .catch((e) => console.error(e));
      });
   };

   cancelarClick = () => {
      return new Promise((resolve) => {
         this.setState(
            {
               navegando: this.props.select || this.props.form ? false : true,
               alterando: false,
               incluindo: false,
               itemSelecionado: this.getItemVazio(),
            },
            () => {
               if (this.props.select && this.props.select.aoCancelar) {
                  this.props.select.aoCancelar();
               }
               if (this.props.modal && this.props.aoCancelar) {
                  this.props.aoCancelar();
               }
               if (this.props.form && this.props.form.aoCancelar) {
                  this.props.form.aoCancelar();
               }
               resolve();
            }
         );
      });
   };

   excluirClick = (item) => {
      if (this.antesDeExcluir) {
         this.antesDeExcluir(this).then(() => {
            showConfirm(this.props.lang.formularioPadrao.mensagens.desejaRealmenteExcluirEsteRegistro, () => {
               this.excluir(item);
            });
         });
      } else {
         showConfirm(this.props.lang.formularioPadrao.mensagens.desejaRealmenteExcluirEsteRegistro, () => {
            this.excluir(item);
         });
      }
   };

   excluir = (item) => {
      this.props.api.delete(this.props.url + '?id=' + item.id).then(() => {
         showInfo(this.props.lang.formularioPadrao.mensagens.excluidoComSucesso);
         this.setState({
            navegando: true,
            alterando: false,
            incluindo: false,
            items: [],
            itemSelecionado: this.getItemVazio(),
         });
         this.filtrar(this);
      });
   };

   renderizarCabecaolhoAcoes = () => {
      return this.props.select || this.props.form ? null : (
         <td className='acoes'>
            <div>{this.props.lang.formularioPadrao.acoes}</div>
         </td>
      );
   };

   renderizarCodigo = (codigo) => {
      return (
         <td className='codigo'>
            <div>{codigo}</div>
         </td>
      );
   };

   renderizarAcoes = (item) => {
      let result = [];
      if (!this.props.select && !this.props.form) {
         result.push(
            <td key='acoes' className='acoes'>
               <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {
                     <div style={{ display: 'table-cell' }}>
                        <BotaoAlterarItemDeCadastro
                           onClick={() => this.editarClick(item)}
                           title={this.props.lang.formularioPadrao.alterar}
                        />
                     </div>
                  }
                  {this.getState().podeExcluir && (
                     <div style={{ display: 'table-cell' }}>
                        <BotaoExcluirItemDeCadastro
                           onClick={() => this.excluirClick(item)}
                           title={this.props.lang.formularioPadrao.excluir}
                        />
                     </div>
                  )}
                  {this.getAcoesDaTabela ? this.getAcoesDaTabela(item) : null}
               </div>
            </td>
         );
      }
      return result;
   };

   salvar = () => {
      return new Promise((resolve, reject) => {
         if (this.antesDeSalvar) {
            this.antesDeSalvar(this);
         }

         let self = this;
         this.setState({ botaoSalvarHabilitado: false });
         this.getObjetoDeDados(this)
            .then((input) => {
               this.setState({ botaoSalvarHabilitado: true });
               if (self.getState().incluindo) {
                  let postFn = self.props.protected ? self.props.api.protectedPost : self.props.api.post;
                  postFn(self.props.url, input)
                     .then((id) => {
                        if (this.props.aposSalvar) {
                           this.props.aposSalvar();
                        }

                        if (
                           (self.props.select && self.props.select.aoSelecionar) ||
                           (self.props.modal && self.props.aoSelecionar)
                        ) {
                           self.props.api
                              .getAll(self.props.url + '?id=' + id.toString())
                              .then((result) => {
                                 if (self.props.select && self.props.select.aoSelecionar) {
                                    self.props.select.aoSelecionar(result.items[0], true);
                                 }
                                 if (self.props.modal && self.props.aoSelecionar) {
                                    self.props.aoSelecionar(result.items[0], true);
                                 }
                              })
                              .catch((e) => console.error(e));
                        } else if (
                           (self.props.form && self.props.form.aoSelecionar) ||
                           (self.props.modal && self.props.aoSelecionar)
                        ) {
                           self.props.api
                              .getAll(self.props.url + '?id=' + id.toString())
                              .then((result) => {
                                 if (self.props.form && self.props.form.aoSelecionar) {
                                    self.props.form.aoSelecionar(result.items[0], true);
                                 }
                                 if (self.props.modal && self.props.aoSelecionar) {
                                    self.props.aoSelecionar(result.items[0], true);
                                 }
                              })
                              .catch((e) => console.error(e));
                        } else {
                           if (!this.props.id) {
                              self.setState({
                                 navegando: true,
                                 alterando: false,
                                 incluindo: false,
                                 items: [],
                                 itemSelecionado: self.getItemVazio(),
                              });
                              self.filtrar(self);
                           }
                        }
                     })
                     .catch(reject);
               } else if (self.getState().alterando) {
                  let putFn = self.props.protected ? self.props.api.protectedPut : self.props.api.put;
                  putFn(self.props.url, input)
                     .then((result) => {
                        if (this.props.aposSalvar) {
                           this.props.aposSalvar();
                        }

                        if (
                           (self.props.select && self.props.select.aoSelecionar) ||
                           (self.props.modal && self.props.aoSelecionar)
                        ) {
                           self.props.api
                              .getAll(self.props.url + '?id=' + input.id.toString())
                              .then((result) => {
                                 if (self.props.select && self.props.select.aoSelecionar) {
                                    self.props.select.aoSelecionar(result.items[0], true);
                                 }
                                 if (self.props.modal && self.props.aoSelecionar) {
                                    self.props.aoSelecionar(result.items[0], true);
                                 }
                              })
                              .catch((e) => console.error(e));
                        } else {
                           if (!this.props.id) {
                              self.setState({
                                 navegando: true,
                                 alterando: false,
                                 incluindo: false,
                                 items: [],
                                 itemSelecionado: self.getItemVazio(),
                                 consultou: true,
                              });
                           }
                           showInfo(this.props.lang.formularioPadrao.mensagens.salvoComSucesso).then(() => {
                              self.filtrar(self);
                           });
                        }
                     })
                     .catch(reject);
               }
               resolve();
            })
            .catch((e) => {
               this.setState({ botaoSalvarHabilitado: true });
               reject(e);
            });
      });
   };

   getCabecalho = () => {
      return (
         <Navbar
            expand='md'
            style={{
               paddingLeft: 10,
               paddingRight: 10,
               paddingBottom: 2,
               paddingTop: 0,
               marginBottom: 0,
               borderBottom: '1px solid rgb(206, 212, 218)',
               backgroundColor: 'whitesmoke',
            }}
         >
            {!this.props.esconderTitulo && (
               <span
                  className='navbar-brand'
                  href='#'
                  style={{ color: LayoutParams.colors.corSecundaria, fontSize: 26, paddingTop: 6 }}
               >
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                     <div style={{ cursor: 'pointer' }} onClick={this.formConsultaSubmit}>
                        {this.props.titulo}
                     </div>
                     <div style={{ paddingLeft: 8 }}>
                        {this.getState().navegando && this.getState().podeIncluir && (
                           <div style={{ display: 'table-cell', marginLeft: '80%', width: '100%' }}>
                              <div title={this.props.lang.formularioPadrao.novo + ' F1'}>
                                 <FontAwesomeIcon
                                    className='custom-hover'
                                    onClick={this.inserirClick}
                                    icon={faPlusCircle}
                                    style={{
                                       fontSize: 22,
                                       color: LayoutParams.colors.corSecundaria,
                                       cursor: 'pointer',
                                    }}
                                 />
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </span>
            )}

            {this.getState().navegando && (
               <Fragment>
                  {!this.props.esconderTitulo && (
                     <Fragment>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse id='basic-navbar-nav'>
                           <Nav className='mr-auto'>
                              {this.props.menus &&
                                 this.props.menus.map((menu, index) => {
                                    const getSisuacaoDoMenu = (m) => {
                                       let result = false;

                                       try {
                                          result = m && (!m.habilitado || (m.habilitado && m.habilitado()));
                                       } catch (error) {}
                                       return result;
                                    };

                                    const menuHabilitado = getSisuacaoDoMenu(menu);

                                    return menu.subMenus ? (
                                       <NavDropdown
                                          title={menu.titulo}
                                          id='basic-nav-dropdown'
                                          style={{ paddingTop: 4 }}
                                       >
                                          {menu.subMenus.map((subMenu, index2) => {
                                             const subMenuHabilitado = getSisuacaoDoMenu(subMenu);

                                             return (
                                                <div
                                                   className='dropdown-item'
                                                   style={{
                                                      cursor: subMenuHabilitado ? 'pointer' : 'not-allowed',
                                                      fontSize: 14,
                                                      paddingLeft: 6,
                                                      paddingRight: 6,
                                                      ...subMenu.style 
                                                   }}
                                                   key={index2}
                                                   disabled={!subMenuHabilitado}
                                                   onClick={() => (subMenuHabilitado && subMenu.acao ? subMenu.acao() : null)}
                                                >
                                                   {subMenu.conteudo}
                                                </div>
                                             );
                                          })}
                                       </NavDropdown>
                                    ) : (
                                       <div
                                          className='nav-link'
                                          style={{
                                             cursor: menuHabilitado ? 'pointer' : 'not-allowed',
                                             padding: '12px 6px 3px 3px',
                                          }}
                                          key={index}
                                          disabled={!menuHabilitado}
                                          onClick={() => (menuHabilitado && menu.acao ? menu.acao() : null)}
                                       >
                                          {menu.titulo}
                                       </div>
                                    );
                                 })}
                           </Nav>

                           {this.getState().navegando && this.getFiltros()}
                        </Navbar.Collapse>
                     </Fragment>
                  )}

                  {this.props.esconderTitulo && this.getState().navegando && this.getFiltros()}
               </Fragment>
            )}

            {(this.getState().incluindo || this.getState().alterando) && (
               <div
                  style={{
                     display: 'table-cell',
                     width: '100%',
                     textAlign: 'end',
                  }}
               >
                  <ButtonGroup
                     className='div-acoes-do-formulario'
                     style={{
                        marginLeft: 'auto',
                        marginRight: 0,
                        marginTop: 5,
                        borderRadius: 4,
                     }}
                  >
                     {!this.props.id && (
                        <Button
                           id={'btnCancelar'}
                           icon={<FontAwesomeIcon icon={faTimesCircle} />}
                           onClick={this.cancelarClick}
                           style={{ width: 130, display: 'flex', padding: '4px 2px 2px 2px', height: 33 }}
                           title={
                              this.props.lang.formularioPadrao.cancelar +
                              ' ' +
                              (this.getState().incluindo
                                 ? this.props.lang.formularioPadrao.inclusao
                                 : this.props.lang.formularioPadrao.alteracoes)
                           }
                           text={
                              this.getState().podeAlterar
                                 ? this.props.lang.formularioPadrao.cancelar
                                 : this.props.lang.formularioPadrao.fechar
                           }
                        />
                     )}
                     {((this.getState().incluindo && this.getState().podeIncluir) ||
                        (this.getState().alterando && this.getState().podeAlterar)) && (
                        <Button
                           id={'btnSalvar'}
                           icon={<FontAwesomeIcon icon={faSave} />}
                           disabled={!this.getState().botaoSalvarHabilitado}
                           onClickAsync={this.salvar}
                           style={{ width: 130, display: 'flex', padding: '4px 2px 2px 2px', height: 33 }}
                           text={this.props.lang.formularioPadrao.salvar}
                           inProgressText={this.props.lang.formularioPadrao.salvando}
                        />
                     )}
                  </ButtonGroup>
               </div>
            )}
         </Navbar>
      );
   };

   getFiltros = () => {
      return (
         <form onSubmit={this.formConsultaSubmit} action='/' name='formConsulta' id='formConsulta'>
            <div style={{ paddingTop: 5 }}>{this.renderizarFiltros(this)}</div>
         </form>
      );
   };

   getCabecalhosDaTabela = () => {
      const isMobile = this.isMobile();

      var titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      if (!titulos || isMobile) {
         return null;
      }
      return (
         <div className='div-cabecalho-tabela-formulario-padrao'>
            <table className='cabecalho-tabela-formulario-padrao'>
               <thead>
                  <tr>
                     {titulos.map((item, index) => {
                        if (!mostrarCodigo && index === 0) return null;
                        else
                           return (
                              <td
                                 key={index}
                                 className={item.className}
                                 onClick={() => {
                                    if (item.orderby) {
                                       let ordenacao = item.orderby;
                                       if (ordenacao === this.getState().ordenacao) {
                                          ordenacao += ' desc';
                                       }
                                       this.setState({ ordenacao: ordenacao }, () => {
                                          this.filtrar();
                                       });
                                    }
                                 }}
                                 style={{ cursor: item.orderby ? 'pointer' : 'default', width: item.width }}
                                 title={item.titulo}
                              >
                                 {item.titulo}
                              </td>
                           );
                     })}
                     {this.renderizarCabecaolhoAcoes()}
                  </tr>
               </thead>
            </table>
         </div>
      );
   };

   getLista = () => {
      const isMobile = this.isMobile();
      const titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();

      if (!titulos) {
         return null;
      }

      const tamanhos = titulos.map((i) => i.width);
      const classes = titulos.map((i) => i.className);
      const textoDosTitulos = titulos.map((i) => i.titulo);
      let result = null;

      if (isMobile) {
         result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center' }}>
                        <span>
                           {this.getState().carregando
                              ? this.props.lang.formularioPadrao.mensagens.carregando
                              : this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}
                        </span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            (result = this.getState().itens.map((item, rowIndex) => {
               let dados = this.getDadosDaTabela(item);
               return !item || !dados ? null : (
                  <div
                     key={rowIndex}
                     className={this.props.select || this.props.form ? 'noselect' : null}
                     style={{
                        cursor: this.props.select || this.props.form ? 'pointer' : 'default',
                        border: '1px solid rgb(206, 212, 218)',
                        borderRadius: 5,
                        margin: 5,
                        padding: 3,
                     }}
                     onDoubleClick={() => this.handleDoubleClick(item)}
                     onClick={() => this.handleClick(item)}
                  >
                     {[
                        dados.map((dado, campoIndex) => {
                           return (
                              dado && (
                                 <div key={campoIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ display: 'table-cell', fontWeight: 600 }}>
                                       {textoDosTitulos[campoIndex]}:&nbsp;
                                    </div>
                                    <div style={{ display: 'table-cell' }}>
                                       <span style={{ wordWrap: 'anywhere' }}>{dado}</span>
                                    </div>
                                 </div>
                              )
                           );
                        }),
                        this.renderizarAcoes(item),
                     ]}
                  </div>
               );
            }))
         );
      } else {
         result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center', color: '#999' }}>
                        <span>
                           {this.getState().carregando
                              ? this.props.lang.formularioPadrao.mensagens.carregando
                              : this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}
                        </span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            <table className='tabela-formulario-padrao table-hover'>
               <tbody>
                  {this.getState().itens.map((item, rowIndex) => {
                     let dados = this.getDadosDaTabela(item);
                     return !item || !dados ? (
                        <tr>{[this.renderizarAcoes(item)]}</tr>
                     ) : (
                        <tr
                           key={rowIndex}
                           className={this.props.select || this.props.form ? 'noselect' : null}
                           style={{ cursor: this.props.select || this.props.form ? 'pointer' : 'default' }}
                           onDoubleClick={() => this.handleDoubleClick(item)}
                           onClick={() => this.handleClick(item)}
                        >
                           {[
                              dados.map((dado, campoIndex) => {
                                 if (!mostrarCodigo && campoIndex === 0) return null;
                                 else
                                    return (
                                       <td
                                          key={campoIndex}
                                          className={classes[campoIndex]}
                                          style={{ width: tamanhos[campoIndex] }}
                                       >
                                          {dado}
                                       </td>
                                    );
                              }),
                              this.renderizarAcoes(item),
                           ]}
                        </tr>
                     );
                  })}
               </tbody>
            </table>
         );
      }
      return result;
   };

   getFormulario = () => {
      return (
         <Row
            className={
               this.props.formularioClassName ? this.props.formularioClassName : 'justify-content-md-center mx-0'
            }
            style={{
               overflowY: this.props.overflowY ? this.props.overflowY : 'auto',
               overflowX: this.props.overflowX ? this.props.overflowX : 'hidden',
               paddingTop: 5,
            }}
         >
            <Col
               style={{
                  maxWidth: this.props.maxWidth ? this.props.maxWidth : 800,
                  minHeight: this.props.minHeight ? this.props.minHeight : 400,
               }}
            >
               {this.renderizarFormulario(this)}
            </Col>
         </Row>
      );
   };

   getNavegador = () => {
      return (
         <div
            style={{
               width: '100%',
               display: 'flex',
               justifyContent: 'flex-end',
               padding: '10px 2px 10px 10px',
               position: 'relative',
            }}
         >
            {(this.props.select || this.props.form || this.props.modal) && !this.getState().incluindo && (
               <div style={{ width: 150, textAlign: 'left' }}>
                  <Button
                     variant='secondary'
                     onClick={this.cancelarClick}
                     style={{ width: 150 }}
                     text={this.props.lang.formularioPadrao.fechar}
                  />
               </div>
            )}
            {this.getState().itens ? (
               <React.Fragment>
                  <div style={{ width: '100%', textAlign: 'left', paddingTop: 10, paddingLeft: 5 }}>
                     {this.getState().quantidadeTotalDeDados ? (
                        <div>
                           <span>{this.props.lang.formularioPadrao.mostrando}&nbsp; </span>
                           <span>{this.getState().quantidadeDeDados}</span>
                           <span>&nbsp;{this.props.lang.formularioPadrao.de}&nbsp;</span>
                           <span>{this.getState().quantidadeTotalDeDados}</span>
                        </div>
                     ) : null}
                  </div>
                  <ButtonGroup className='mr-2' style={{ minWidth: 120 }}>
                     <Button
                        title={this.props.lang.formularioPadrao.carregarMais}
                        text=''
                        icon={<FontAwesomeIcon icon={faAngleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(1)}
                        disabled={!this.getState().podeAvancar}
                        style={{ cursor: this.getState().podeAvancar ? 'pointer' : 'not-allowed', padding: 0 }}
                     />
                     <Button
                        title={this.props.lang.formularioPadrao.carregarTodos}
                        text=''
                        icon={<FontAwesomeIcon icon={faAngleDoubleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(2)}
                        disabled={!this.getState().podeCarregarTodos}
                        style={{
                           cursor: this.getState().podeCarregarTodos ? 'pointer' : 'not-allowed',
                           padding: 0,
                        }}
                     />
                  </ButtonGroup>
               </React.Fragment>
            ) : null}
         </div>
      );
   };

   render() {
      var fn = () => {
         return (
            <div
               id='formularioPadrao'
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '100%',
                  overflowX: 'hidden',
                  width: '100%',
                  maxWidth: '100%',
                  height: '100%',
               }}
               tabIndex='0'
               onKeyDown={(e) => {
                  if (e.keyCode === 112) {
                     this.inserirClick();
                  }

                  if (e.keyCode === 115) {
                     if (this.getState().incluindo || this.getState().alterando) {
                        this.salvar();
                     }
                  }

                  if (e.keyCode === 27) {
                     if (this.getState().navegando) {
                        this.limparFiltro();
                     }

                     if (this.getState().incluindo || this.getState().alterando) {
                        this.cancelarClick();
                     }
                  }
               }}
            >
               {this.getCabecalho()}

               {this.getState().navegando && this.getCabecalhosDaTabela()}

               {this.getState().navegando && <div className='div-tabela-formulario-padrao'>{this.getLista()}</div>}

               {(this.getState().incluindo || this.getState().alterando) &&
                  this.getState().mostrarFormulario &&
                  this.getFormulario &&
                  this.getFormulario()}
               {this.getState().navegando && this.getNavegador()}
            </div>
         );
      };

      if (this.props.modal) {
         return (
            <Modal
               show={true}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ inserindo: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  {fn()}
               </Modal.Body>
            </Modal>
         );
      } else {
         return fn();
      }
   }
}

export const BotaoAlterarItemDeCadastro = ({ onClick, title }) => {
   return (
      <FontAwesomeIcon
         className='custom-hover'
         title={title}
         style={{
            fontSize: 23,
            paddingTop: 2,
            marginLeft: 3,
            marginRight: 3,
            color: LayoutParams.colors.corSecundaria,
         }}
         cursor='pointer'
         icon={faEdit}
         onClick={onClick}
      />
   );
};

export const BotaoExcluirItemDeCadastro = ({ onClick, title }) => {
   return (
      <FontAwesomeIcon
         className='custom-hover'
         title={title}
         style={{
            fontSize: 23,
            paddingTop: 2,
            marginLeft: 3,
            marginRight: 3,
            color: LayoutParams.colors.corSecundaria,
         }}
         cursor='pointer'
         icon={faTrashAlt}
         onClick={onClick}
      />
   );
};
