import React, { Component, Fragment } from 'react';
import Line from './Line';
import { LayoutParams } from '../config/LayoutParams';

export default class ReportHeader extends Component {
   render() {
      return (
         <Fragment>
            <Line />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', width: '100%' }}>{this.props.children}</div>
               <div style={{ display: 'table-cell', height: 60 , paddingTop: 5, paddingBottom: 5}} className='show-on-print-only'>
                  <img alt='' src={LayoutParams.imgReportLogo} style={{height: '100%'}}/>
               </div>
            </div>

            <Line />
         </Fragment>
      );
   }
}
