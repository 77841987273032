import { Row, Col, FormGroup, Container, Form } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import Label from '../../components/Label';
import {
   buildQueryString,
   dateTimeToString,
   formatDate,
   objectToQueryString,
   updateState,
   prepareSearchable,
} from '../../utils/Functions';
import LocalView from './LocalView';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import TimeInput from '../../components/TimeInput';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { showError } from '../../components/Messages';
import Button from '../../components/Button';
import { Filler } from '../../components/Filler';
import IntegerInput from '../../components/IntegerInput';

const STATUS_DA_TELA = {
   Carregando1: 1,
   Carregando2: 2,
   Carregando3: 3,
   Carregando4: 4,
   Carregado: 9,
   Erro: -1,
};

let LANG = {};

export default class RelatorioDeMovimentacaoDeEquipamentoEntreLocaisView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dataInicial: new Date(),
         horaInicial: '00:00',
         horaFinal: '23:59',
         dataFinal: new Date(),
         statusDaTela: STATUS_DA_TELA.Carregado,
         duracao: 30,
         nomeDoLocalDeOrigemFiltrado: null,
         nomeDoLocalDeDestinoFiltrado: null,
      };
      LANG = props.lang;
   }

   componentWillUnmount = () => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
   };

   iniciarProcessamento = () => {
      this.atualizarEtapadoCarregamentoId = setInterval(() => {
         if (this.state.statusDaTela === STATUS_DA_TELA.Carregando1) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando2 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando2) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando3 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando3) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando4 });
         }
      }, 10000);

      this.setState({ mensagemDeErro: null, statusDaTela: STATUS_DA_TELA.Carregando1 });
   };

   finalizarProcessamentoComSucesso = () => {
      this.setState({ mensagemDeErro: null, statusDaTela: STATUS_DA_TELA.Carregado });
   };

   finalizarProcessamentoComErro = (e) => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
      this.setState({ mensagemDeErro: this.props.api.getErrorMessage(e), statusDaTela: STATUS_DA_TELA.Erro });
   };

   finalizarProcessamentoVazio = () => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
      this.setState({
         mensagemDeErro: LANG.relatorioDeMovimentacao.nenhumRegistroEncontrado,
         statusDaTela: STATUS_DA_TELA.Erro,
      });
   };

   consultar = () => {
      this.setState({
         dados: null,
      });

      return new Promise((resolve, reject) => {
         if (!this.state.dataInicial) {
            showError(LANG.relatorioDeMovimentacao.informeADataInicial);
            reject();
            return;
         }

         if (!this.state.dataFinal) {
            showError(LANG.relatorioDeMovimentacao.informeADataFinal);
            reject();
            return;
         }

         this.iniciarProcessamento();

         this.props.api
            .get(
               '/equipamento/relatoriodemovimentacaoentrelocais?' +
                  objectToQueryString({
                     dataInicial: formatDate(this.state.dataInicial),
                     horaInicial: this.state.horaInicial,
                     horaFinal: this.state.horaFinal,
                     dataFinal: formatDate(this.state.dataFinal),
                     localAnterior: this.state.localAnterior ? this.state.localAnterior.id : null,
                     localAtual: this.state.localAtual ? this.state.localAtual.id : null,
                     duracao: this.state.duracao,
                  }),
               false,
               null,
               false,
               false
            )
            .then((result) => {
               this.setState(
                  {
                     dados: result,
                     nomeDoLocalDeOrigemFiltrado: this.state.localAnterior ? this.state.localAnterior.nome : null,
                     nomeDoLocalDeDestinoFiltrado: this.state.localAtual ? this.state.localAtual.nome : null,
                  },
                  () => {
                     if (result.itens && result.itens.length > 0) {
                        this.finalizarProcessamentoComSucesso();
                     } else {
                        this.finalizarProcessamentoVazio();
                     }
                     resolve();
                  }
               );
            })
            .catch((e) => {               
               this.finalizarProcessamentoComErro(e);
               reject(e);
            });
      });
   };

   render() {
      let result = (
         <Fragment>
            <Container style={{ overflowY: 'auto', maxHeight: '100%', minWidth: '100%', margin: 0, paddingTop: 5 }}>
               <Row>
                  <Col>
                     <Form.Group style={{ marginBottom: 8 }}>
                        <Label>{LANG.relatorioDeMovimentacao.localDeOrigem}</Label>
                        <Select
                           name={'localAnterior'}
                           defaultValue={this.state.localAnterior}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nome}
                           onSelect={(i) => {
                              this.setState({ localAnterior: i });
                           }}
                           formularioPadrao={(select) => {
                              return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                           }}
                           noDropDown={true}
                           getFilterUrl={(text) =>
                              '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                           placeholder={''}
                           readOnlyColor={'#ffffff'}
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group style={{ marginBottom: 8 }}>
                        <Label>{LANG.relatorioDeMovimentacao.localDeDestino}</Label>
                        <Select
                           name={'localAtual'}
                           defaultValue={this.state.localAtual}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nome}
                           onSelect={(i) => {
                              this.setState({ localAtual: i });
                           }}
                           formularioPadrao={(select) => {
                              return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                           }}
                           noDropDown={true}
                           getFilterUrl={(text) =>
                              '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                           placeholder={''}
                           readOnlyColor={'#ffffff'}
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.dataInicial}</Label>
                        <DateInput
                           defaultValue={this.state.dataInicial}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataInicial = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.horaInicial}</Label>
                        <TimeInput
                           defaultValue={this.state.horaInicial}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.horaInicial = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.dataFinal}</Label>
                        <DateInput
                           defaultValue={this.state.dataFinal}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataFinal = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.horaFinal}</Label>
                        <TimeInput
                           defaultValue={this.state.horaFinal}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.horaFinal = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>

                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.permanenciaMinima + '(s)'}</Label>
                        <IntegerInput
                           defaultValue={this.state.duracao}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.duracao = e.floatValue;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <ReactToPrint content={() => this.reportComponentRef}>
                        <PrintContextConsumer>
                           {({ handlePrint }) => (
                              <Row>
                                 <Col style={{ maxWidth: 180 }}>
                                    <Button
                                       onClickAsync={() => {
                                          return new Promise((resolve, reject) => {
                                             this.consultar()
                                                .then(() => {
                                                   resolve();
                                                })
                                                .catch(reject);
                                          });
                                       }}
                                       inProgressText={LANG.relatorioDeMovimentacao.gerando}
                                       text={LANG.relatorioDeMovimentacao.gerar}
                                    />
                                 </Col>
                                 {this.state.dados && this.state.dados.itens && this.state.dados.itens.length > 0 && (
                                    <Col style={{ maxWidth: 180, paddingLeft: 0 }}>
                                       <Button
                                          onClick={() => {
                                             handlePrint();
                                          }}
                                          text={LANG.relatorioDeMovimentacao.imprimir}
                                       />
                                    </Col>
                                 )}
                              </Row>
                           )}
                        </PrintContextConsumer>
                     </ReactToPrint>
                  </Col>
               </Row>

               <br />
               <br />

               {this.state.statusDaTela >= STATUS_DA_TELA.Carregando1 &&
                  this.state.statusDaTela <= STATUS_DA_TELA.Carregando4 && (
                     <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        <div style={{ margin: 'auto', paddingTop: 30 }}>
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando1 && (
                              <span>{LANG.relatorioDeMovimentacao.consultandoOHistoricoDeMovimentacao}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando2 && (
                              <span>{LANG.relatorioDeMovimentacao.baixandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando3 && (
                              <span>{LANG.relatorioDeMovimentacao.processandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando4 && (
                              <span>{LANG.relatorioDeMovimentacao.preparandoExibicao}</span>
                           )}
                        </div>
                     </div>
                  )}

               {this.state.statusDaTela === STATUS_DA_TELA.Erro && (
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        <span>{this.state.mensagemDeErro}</span>
                     </div>
                  </div>
               )}

               {this.state.statusDaTela === STATUS_DA_TELA.Carregado && (
                  <div>
                     <div ref={(el) => (this.reportComponentRef = el)}>{this.state.dados && this.relatorio()}</div>
                     <br />
                     <br />
                  </div>
               )}
            </Container>
         </Fragment>
      );

      return result;
   }

   relatorio = () => {
      let result = null;

      if (this.state.dados && this.state.dados.itens) {
         result = (
            <React.Fragment>
               <div style={{ paddingTop: 3, fontSize: 16 }}>
                  <strong>{LANG.relatorioDeMovimentacao.relatorioDeMovimentacaoEntreLocais}</strong>
               </div>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>
                        {LANG.relatorioDeMovimentacao.periodo +
                           ': ' +
                           dateTimeToString(this.state.dados.dataInicial, this.props.lang) +
                           ' - ' +
                           dateTimeToString(this.state.dados.dataFinal, this.props.lang)}
                     </span>
                  </div>
               </div>

               {this.state.nomeDoLocalDeOrigemFiltrado && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <div style={{ display: 'table-cell' }}>
                        <span>
                           {LANG.relatorioDeMovimentacao.localDeOrigem + ': ' + this.state.nomeDoLocalDeOrigemFiltrado}
                        </span>
                     </div>
                  </div>
               )}

               {this.state.nomeDoLocalDeDestinoFiltrado && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <div style={{ display: 'table-cell' }}>
                        <span>
                           {LANG.relatorioDeMovimentacao.localDeDestino +
                              ': ' +
                              this.state.nomeDoLocalDeDestinoFiltrado}
                        </span>
                     </div>
                  </div>
               )}

               <Filler height={5} />
               {this.state.dados.itens.map((grupo, index) => {
                  return (
                     <div style={{ fontSize: 10 }}>
                        <LinhaDeCabecalho>
                           <Coluna
                              texto={grupo.cabecalho.nomeDoLocalAnterior + ' -> ' + grupo.cabecalho.nomeDoLocalAtual}
                              largura={'100%'}
                           />
                        </LinhaDeCabecalho>

                        <LinhaDeCabecalho>
                           <Coluna texto={LANG.relatorioDeMovimentacao.equipamento} largura={'33%'} />
                           <Coluna texto={LANG.relatorioDeMovimentacao.numeroDeSerie} largura={'15%'} />
                           <Coluna texto={LANG.relatorioDeMovimentacao.patrimonio} largura={'12%'} />
                           <Coluna texto={LANG.relatorioDeMovimentacao.entrada} largura={'15%'} />
                           <Coluna texto={LANG.relatorioDeMovimentacao.saida} largura={'15%'} />
                           <Coluna texto={LANG.relatorioDeMovimentacao.tempo + ' (s)'} largura={'10%'} />
                        </LinhaDeCabecalho>

                        {grupo.movimentacao.map((item, index) => {
                           return (
                              <div key={index} style={{ border: '1px solid' }}>
                                 <Linha>
                                    <Coluna texto={item.nomeDoMonitorado} largura={'33%'} />
                                    <Coluna texto={item.numeroDeSerie} largura={'15%'} />
                                    <Coluna texto={item.patrimonio} largura={'12%'} />
                                    <Coluna texto={dateTimeToString(item.dataDeEntrada, this.props.lang)} largura={'15%'} />
                                    <Coluna texto={dateTimeToString(item.dataDeSaida, this.props.lang)} largura={'15%'} />
                                    <Coluna texto={item.tempo} largura={'10%'} />
                                 </Linha>
                              </div>
                           );
                        })}

                        <Filler height={10} />
                     </div>
                  );
               })}
            </React.Fragment>
         );
      }

      return result;
   };
}

class Linha extends React.Component {
   render() {
      return (
         <div className={'linha-relatorio'} style={{ display: 'flex', flexDirection: 'row' }}>
            {this.props.children}
         </div>
      );
   }
}

class LinhaDeCabecalho extends React.Component {
   render() {
      return (
         <div
            className={'linha-relatorio'}
            style={{
               display: 'flex',
               flexDirection: 'row',
               fontWeight: 'bold',
               border: '1px solid',
               backgroundColor: 'lightgray',
            }}
         >
            {this.props.children}
         </div>
      );
   }
}

const Coluna = ({ texto, largura }) => {
   return (
      <div style={{ display: 'table-cell', width: largura }}>
         <span>{texto}</span>
      </div>
   );
};
