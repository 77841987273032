import React, { Component, Fragment } from 'react';
import { Stage, Layer, Image, Rect, Circle, Text } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { showError, showInfo } from '../../components/Messages';
import gatewayGreen from './../../contents/img/gateway-green.png';
import { Col, Modal, Row } from 'react-bootstrap';
import gatewayRed from './../../contents/img/gateway-red.png';
/*import imagemEquipamentoOnline from './../../contents/img/equipamento_online.png';
import imagemEquipamentoOffline from './../../contents/img/equipamento_offline.png';
import imagemEquipamentoOrange from './../../contents/img/equipamento_orange.png';*/
import gatewayOrange from './../../contents/img/gateway-orange.png';
import Select from '../../components/Select';
import { buildQueryString, prepareSearchable } from '../../utils/Functions';
import ListaDeMonitoradosPorGatewayView from './ListaDeMonitoradosPorGatewayView';
import EquipamentoView from './EquipamentoView';
import MapaView from './MapaView';
import { LayoutParams } from '../../config/LayoutParams';
import logoMedTrackPreto from './../../contents/img/logo-002.png';
import SessionManager from '../../models/SessionManager';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Button from '../../components/Button';
import { ActionButton } from '../../components/ActionButton';
import HistoricoDeMonitoramentoView from './HistoricoDeMonitoramentoView';
import { faChartBar, faMap } from '@fortawesome/free-solid-svg-icons';
import { ViewController } from '../../components/ViewController';
import RelatorioDeMovimentacaoView from './RelatorioDeMovimentacaoView';

let timer = 0;
let delay = 200;
let prevent = false;

export default class HomeEmpresaMedTrackComMapa extends Component {
   constructor(props) {
      super(props);
      this.state = {
         mapa: null,
         id: null,
         mapas: null,
         monitoramentos: [],
         mostrarComboMapa: true,
         mostrarComboEquipamento: true,
         ajustarATela: true,
         titulo: props.lang.telaInicial.monitoramento,
         selectedViewNumber: 1,
         gatewayScale: 1,
      };
   }

   componentDidMount = () => {
      window.addEventListener('resize', () => {
         let mapa = this.state.mapa;
         this.setState({ mapa: null }, () => this.setState({ mapa: mapa }));
      });

      window.addEventListener('ontogglemenu', (showMenu) => {
         let ajustarATela = this.state.ajustarATela;
         this.setState({ ajustarATela: null }, () => this.setState({ ajustarATela: ajustarATela }));
      });

      this.props.api
         .get(
            '/mapa' +
               buildQueryString(null, null, 'Predio.Nome, Nome', {
                  situacao: 1,
               }),
            false
         )
         .then((result) => {
            this.setState(
               {
                  mapas: result.items,
               },
               () => {}
            );
         })
         .catch((e) => {
            this.setState({
               mapas: [],
               mapa: null,
            });
         });

      this.refreshIntervalId = setInterval(() => {
         if (!this.state.mapa) {
            return;
         }
         this.atualizarGateways();
         if (this.state.equipamento) {
            this.atualizarMonitorados(this.state.equipamento);
         }
      }, 5000);

      let sessionManager = new SessionManager();
      let filtrosDoMapaInicial = sessionManager.filtrosDoMapaInicial();
      if (filtrosDoMapaInicial != null) {
         if (filtrosDoMapaInicial.mapa) {
            this.setMapa(filtrosDoMapaInicial.mapa);
         }
         if (filtrosDoMapaInicial.perfilDeVisualizacao) {
            this.setState({ perfilDeVisualizacao: filtrosDoMapaInicial.perfilDeVisualizacao });
         }
      }
   };

   componentWillUnmount = () => {
      if (this.refreshIntervalId) {
         clearInterval(this.refreshIntervalId);
      }
      let sessionManager = new SessionManager();
      sessionManager.salvarFiltrosDoMapaInicial({
         mapa: this.state.mapa,
         zoom: this.state.usarZoom ? this.state.zoom : null,
         perfilDeVisualizacao: this.state.perfilDeVisualizacao,
      });
   };

   atualizarGateways = () => {
      if (!this.state.mapa) {
         this.setState({
            monitoramentos: [],
            erroAoAtualizarGateways: null,
         });
         return;
      }
      this.props.api
         .get('/mapa/monitoramento/resumo?id=' + this.state.mapa.id, false, '', false, false)
         .then((result) => {
            this.setState({
               monitoramentos: result,
               erroAoAtualizarGateways: null,
            });
         })
         .catch((e) => {
            this.setState({
               erroAoAtualizarGateways: e.toString(),
            });
         });
   };

   atualizarImagemDaPlanta = () => {
      this.setState({ imageUrl: null }, () => {
         if (!this.state.mapa) {
            return;
         }
         if (this.state.mapa.imagem) {
            this.props.api
               .get('/file?filename=' + this.state.mapa.imagem.nome, true, 'arraybuffer', false)
               .then((data) => {
                  try {
                     const base64 = Buffer.from(data, 'binary').toString('base64');
                     const imageUrl = 'data:image/png;base64, ' + base64;
                     this.setState({ imageUrl: imageUrl });
                  } catch (e) {
                     console.error(e);
                     showError(this.props.lang.telaInicial.erroAoCarregarArquivo + '. \n\n' + e.toString());
                  }
               })
               .catch((e) => {});
         }
      });
   };

   doDoubleClickAction(item, action) {
      if (action) {
         action(item);
      }
   }

   handleClick(item, action) {
      let self = this;
      timer = setTimeout(function () {
         if (!prevent) {
            self.onRowClick(item, action);
         }
         prevent = false;
      }, delay);
   }

   handleDoubleClick(item, action) {
      clearTimeout(timer);
      prevent = true;
      this.doDoubleClickAction(item, action);
   }

   mostrarItens = (gateway) => {
      this.setState({ mostrarItens: true, gatewaySelecionado: gateway });
   };

   setEquipamento = (equipamento) => {
      this.setState({ equipamento: equipamento }, () => {
         if (equipamento) {
            this.atualizarMonitorados(equipamento);
         } else {
            this.setState({ monitorados: [] });
         }
      });
   };

   atualizarMonitorados = (equipamento) => {
      if (equipamento) {
         this.props.api
            .get(
               '/mapa/monitorados' +
                  buildQueryString(null, null, null, {
                     equipamento: equipamento.id,
                  }),
               false,
               '',
               false,
               false
            )
            .then((r) => {
               let result = r.items;
               let mapaDoEquipamento = result[0].mapa;
               if (!mapaDoEquipamento) {
                  if (equipamento && this.state.ultimoEquipamentoNaoLocalizado !== equipamento) {
                     this.setState({ ultimoEquipamentoNaoLocalizado: equipamento });
                     showError(this.props.lang.telaInicial.equipamentoNaoLocalizado);
                  }
               } else {
                  if (result && result.length > 0) {
                     if (
                        !this.state.mapa ||
                        (mapaDoEquipamento && this.state.mapa && mapaDoEquipamento.id !== this.state.mapa.id)
                     ) {
                        let mapas = this.state.mapas.filter((i) => i.id === mapaDoEquipamento.id);
                        let mapa = null;
                        this.divMapa = null;
                        if (mapas.length > 0) {
                           mapa = mapas[0];
                        }
                        this.setState(
                           { mapa: mapa, monitoramentos: null, monitorados: result, imageUrl: null },

                           () => {
                              if (mapa && this.state.mapa && mapa.id && this.state.mapa.id) {
                                 this.setState({ mostrarComboMapa: false }, () =>
                                    this.setState({ mostrarComboMapa: true })
                                 );
                                 this.atualizarImagemDaPlanta();
                                 this.atualizarGateways();
                              }
                           }
                        );
                     } else {
                        this.setState({ monitorados: result });
                     }
                  }
               }
            });
      } else {
         this.setState({ monitorados: null });
      }
   };

   listarMonitoradosDoGatewaySelecionado = () => {
      return (
         this.state.mapa &&
         this.state.gatewaySelecionado && (
            <Modal
               show={this.state.mostrarItens}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ mostrarItens: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <ListaDeMonitoradosPorGatewayView
                     lang={this.props.lang}
                     api={this.props.api}
                     gatewayId={this.state.gatewaySelecionado.id}
                     titulo={this.getNomeDoLocal(this.state.gatewaySelecionado)}
                     aoFechar={() => {
                        this.setState({ mostrarItens: false });
                     }}
                  />
               </Modal.Body>
            </Modal>
         )
      );
   };

   getNomeDoLocal = (item) => {
      let result = '';
      if (item.gateway.local) {
         if (item.gateway.local.predio) {
            result = item.gateway.local.predio.nome;
         }

         if (item.gateway.local.andar) {
            result =  result + " | " + item.gateway.local.andar + "º " + this.props.lang.telaInicial.andar;
         }
         result =  result + " | " + item.gateway.local.nome;
      }

      return result;
   };

   setMapa = (mapa) => {
      this.divMapa = null;
      this.setState(
         {
            mapa: mapa,
            monitoramentos: null,
            monitorados: null,
            imageUrl: null,
            mostrarComboEquipamento: false,
            equipamento: null,
            perfilDeVisualizacao: null,
         },
         () => {
            this.atualizarImagemDaPlanta();
            this.atualizarGateways();
            this.setState({ mostrarComboEquipamento: true });
         }
      );
   };

   filtros = () => {
      return (
         this.state.mapas &&
         this.state.selectedViewNumber === 1 && (
            <div>
               <Row style={{ marginRight: 7, marginLeft: 0, paddingTop: 4 }}>
                  {this.state.mostrarComboMapa && (
                     <Col style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2 }}>
                        <div>
                           <Select
                              name={'mapa'}
                              defaultValue={this.state.mapa}
                              options={this.state.mapas}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i.predio ? i.predio.nome + ' - ' + i.nome : i.nome)}
                              onSelect={(mapa) => {
                                 this.setMapa(mapa);
                              }}
                              nullText={this.props.lang.telaInicial.selecionarMapa }
                              placeholder={this.props.lang.telaInicial.mapa}
                              formularioPadrao={(select) => {
                                 return (
                                    <MapaView
                                       api={this.props.api}
                                       select={select}
                                       lang={this.props.lang}
                                       filtroExtra={() => ({ situacao: 1 })}
                                    />
                                 );
                              }}
                              filter={(i) => i.situacao.id === 1}
                           />
                        </div>
                     </Col>
                  )}

                  {this.state.mostrarComboEquipamento && (
                     <Fragment>
                        <Col style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2, fontSize: 12 }}>
                           <Select
                              name={'equipamento'}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.nome}
                              placeholder={this.props.lang.telaInicial.equipamento}
                              onSelect={(i) => {
                                 this.setEquipamento(i);
                              }}
                              formularioPadrao={(select) => {
                                 return <EquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/equipamento' + buildQueryString(7, null, 'id', { Searchable: prepareSearchable(text) })
                              }
                           />
                        </Col>

                        {this.state.equipamento && (
                           <Col
                              style={{
                                 //marginTop: 21,
                                 maxWidth: 120,
                                 paddingLeft: 2,
                                 marginRight: 2,
                                 marginLeft: 2,
                                 paddingRight: 2,
                                 fontSize: 12,
                              }}
                           >
                              <Button
                                 text={this.props.lang.telaInicial.historico}
                                 style={{ width: 110 }}
                                 onClick={() => {
                                    this.setState({ mostrarHistoricoDeMonitoramento: true });
                                 }}
                              />
                           </Col>
                        )}

                        {this.state.equipamento && (
                           <Modal
                              show={this.state.mostrarHistoricoDeMonitoramento}
                              scrollable={true}
                              size={'lg'}
                              onHide={() => {}}
                              onKeyDown={(e) => {
                                 if (e.keyCode === 27) this.setState({ mostrarHistoricoDeMonitoramento: false });
                              }}
                              dialogClassName='h-100'
                           >
                              <Modal.Body
                                 style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    position: 'relative',
                                    fontSize: 13,
                                    padding: '0 0 0 0',
                                    maxHeight: '100%',
                                 }}
                              >
                                 <HistoricoDeMonitoramentoView
                                    lang={this.props.lang}
                                    api={this.props.api}
                                    idDoEquipamento={this.state.equipamento.id}
                                    titulo={this.state.equipamento.nome}
                                    aoFechar={() => {
                                       this.setState({ mostrarHistoricoDeMonitoramento: false });
                                    }}
                                 />
                              </Modal.Body>
                           </Modal>
                        )}
                     </Fragment>
                  )}

                  {false &&
                     this.state.mapa &&
                     this.state.mapa.perfisDeVisualizacao &&
                     this.state.mapa.perfisDeVisualizacao.length > 0 && (
                        <Col
                           sm={12}
                           md={6}
                           lg={2}
                           xl={2}
                           style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2 }}
                        >
                           <Select
                              name={this.props.lang.telaInicial.visualizar}
                              options={this.state.mapa.perfisDeVisualizacao}
                              defaultValue={this.state.perfilDeVisualizacao}
                              nullText={''}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 this.setState({ perfilDeVisualizacao: i });
                              }}
                           />
                        </Col>
                     )}
               </Row>
            </div>
         )
      );
   };

   areaDoEquipamentoSelecionado = () => {
      let result = null;
      let area = null;

      if (this.state.equipamento) {
         area = this.state.equipamento.area;
      }

      if (area && area.forma === 'RETANGULO') {
         let coordenadaInicial = area.coordenadas[0];
         if (!coordenadaInicial) {
            coordenadaInicial = { x: 20, y: 20 };
         }
         let coordenadaFinal = area.coordenadas[1];
         if (!coordenadaFinal) {
            coordenadaFinal = { x: 40, y: 40 };
         }
         result = (
            <Rectangle
               shapeProps={{
                  uid: area.uid,
                  x: coordenadaInicial.x,
                  y: coordenadaInicial.y,
                  height: coordenadaFinal.y - coordenadaInicial.y,
                  width: coordenadaFinal.x - coordenadaInicial.x,
                  fill: area.local.cor,
               }}
               text={area.local.nome}
            />
         );
      }
      return result;
   };

   isMobile = () => {
      return window.screen.width <= 600;
   };

   mapa = () => {
      //var offsetX = 0;
      //var offsetY = 0;

      if (this.state.perfilDeVisualizacao) {
         //var cordenadaInicial = this.state.perfilDeVisualizacao.coordenadas[0];
         //var cordenadaFinal = this.state.perfilDeVisualizacao.coordenadas[1];
         //console.log('cordenadaInicial', cordenadaInicial);
         //console.log('cordenadaFinal', cordenadaFinal);
         //offsetX = cordenadaInicial.X;
         //offsetY = cordenadaInicial.y;
      }

      // let marginTop = -300;

      let initialScale = null;

      if (this.state.mapa && this.divMapa) {
         initialScale = this.divMapa.clientWidth / this.state.mapa.largura;
         if (initialScale > 1) {
            initialScale = 1;
         }
         //showInfo(initialScale);
      }

      Konva.pixelRatio = this.isMobile() ? 0.5 : 1;

      return (
         this.state.mapa && (
            <div
               id='mapa'
               style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  minWidth: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
               }}
               ref={(c) => {
                  this.divMapa = c;
               }}
            >
               {this.listarMonitoradosDoGatewaySelecionado()}

               {this.state.mapa && this.divMapa && (
                  <TransformWrapper
                     minScale={0}
                     initialScale={initialScale}
                     centerOnInit={true}
                     wheel={{ step: 0.05 }}
                     onWheelStop={(e) => {
                        let gatewayScale = 1 + (1 - e.state.scale);
                        this.setState({ gatewayScale: gatewayScale });
                     }}
                  >
                     <TransformComponent
                        contentStyle={{
                           width: this.state.mapa.largura,
                           height: this.state.mapa.altura,
                        }}
                        wrapperStyle={{
                           width: '100%',
                           height: '100%',
                        }}
                     >
                        <Stage width={this.state.mapa.largura} height={this.state.mapa.altura}>
                           <Layer>
                              {this.state.imageUrl && <MapImage src={this.state.imageUrl} />}

                              {this.areaDoEquipamentoSelecionado()}

                              {/* { this.state.monitorados &&
                                 this.state.monitorados.map((monitorado, index) => {
                                    return monitorado.coordenada ? (
                                       <EquipamentoImage
                                          index={index}
                                          point={monitorado.coordenada}
                                          img={
                                             this.state.erroAoAtualizarGateways
                                                ? imagemEquipamentoOrange
                                                : monitorado.online
                                                ? imagemEquipamentoOnline
                                                : imagemEquipamentoOffline
                                          }
                                       />
                                    ) : null;
                                 })} */}

                              {this.state.monitoramentos &&
                                 this.state.monitoramentos.map((gateway, index) => {
                                    let nomeDoGateway =
                                       gateway && gateway.gateway && gateway.gateway.local
                                          ? gateway.gateway.local.nome
                                          : '';
                                    let nomeDoGatewayWidth = nomeDoGateway ? nomeDoGateway.length * 10 : 1;

                                    return (
                                       <Fragment>
                                          <GatewayImage
                                             point={gateway.coordenada}
                                             img={
                                                this.state.erroAoAtualizarGateways
                                                   ? gatewayOrange
                                                   : gateway.situacaoDeOperacionalidade &&
                                                     gateway.situacaoDeOperacionalidade.id === 1
                                                   ? gatewayGreen
                                                   : gatewayRed
                                             }
                                             onClick={() => {
                                                if (this.state.erroAoAtualizarGateways) {
                                                   showError(this.state.erroAoAtualizarGateways);
                                                }
                                                showInfo(
                                                   <div>
                                                      <span>MAC: {gateway.gateway.mac}</span>
                                                      <br />
                                                      <span>
                                                         {this.props.lang.telaInicial.patrimonio} :{' '}
                                                         {gateway.gateway.patrimonio}
                                                      </span>
                                                      {this.state.erroAoAtualizarGateways && (
                                                         <div>
                                                            <span>
                                                               {this.props.lang.telaInicial.erroAoConsultarStatus}:{' '}
                                                            </span>
                                                            <br />
                                                            <span>{this.state.erroAoAtualizarGateways}</span>
                                                         </div>
                                                      )}
                                                   </div>,
                                                   this.props.lang.telaInicial.identificacaoDoGateway
                                                );
                                             }}
                                          />
                                          <Circle
                                             x={gateway.coordenada.x + 16}
                                             y={gateway.coordenada.y + 50}
                                             radius={16}
                                             fill={
                                                this.state.erroAoAtualizarGateways
                                                   ? 'orange'
                                                   : gateway.situacaoDeOperacionalidade &&
                                                     gateway.situacaoDeOperacionalidade.id === 1
                                                   ? 'green'
                                                   : 'red'
                                             }
                                             onClick={() => {
                                                this.mostrarItens(gateway);
                                             }}
                                             onTap={() => {
                                                this.mostrarItens(gateway);
                                             }}
                                          />
                                          <Text
                                             text={gateway.quantidade}
                                             width={25}
                                             align={'center'}
                                             fontSize={15}
                                             fontStyle={'bold'}
                                             fill={'#ffffff'}
                                             x={gateway.coordenada.x + 4}
                                             y={gateway.coordenada.y + 44}
                                             onClick={() => {
                                                this.mostrarItens(gateway);
                                             }}
                                             onTap={() => {
                                                this.mostrarItens(gateway);
                                             }}
                                          />

                                          <Text
                                             text={
                                                gateway && gateway.gateway && gateway.gateway.local
                                                   ? gateway.gateway.local.nome
                                                   : ''
                                             }
                                             width={nomeDoGatewayWidth}
                                             align={'center'}
                                             fontSize={15}
                                             fontStyle={'bold'}
                                             fill={
                                                this.state.erroAoAtualizarGateways
                                                   ? 'orange'
                                                   : gateway.situacaoDeOperacionalidade &&
                                                     gateway.situacaoDeOperacionalidade.id === 1
                                                   ? 'green'
                                                   : 'red'
                                             }
                                             x={gateway.coordenada.x + 17 - nomeDoGatewayWidth / 2}
                                             y={gateway.coordenada.y + 70}
                                          />
                                       </Fragment>
                                    );
                                 })}
                           </Layer>
                        </Stage>
                     </TransformComponent>
                  </TransformWrapper>
               )}
            </div>
         )
      );
   };

   render() {
      return (
         <div
            id='div-home-empresa-v2'
            style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}
         >
            {!this.state.mostrarFiltros && false && (
               <div style={{ textAlign: 'center', height: '100%', position: 'fixed', display: 'flex', zIndex: -1 }}>
                  <img alt='logo' src={logoMedTrackPreto} style={{ maxWidth: '50%', margin: 'auto', opacity: 0.3 }} />
               </div>
            )}

            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: '5px solid ' + LayoutParams.colors.corSecundaria,
               }}
            >
               <div
                  style={{
                     paddingTop: 0,
                     paddingLeft: 10,
                     paddingRight: 0,
                     height: 45,
                     display: 'flex',
                     flexDirection: 'row',
                  }}
               >
                  <div
                     style={{
                        display: 'table-cell',
                        width: '35%',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{this.state.titulo}</span>
                  </div>

                  <div
                     style={{
                        display: 'table-cell',
                        width: '65%',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     {this.filtros()}
                  </div>

                  <ActionButton
                     icon={faMap}
                     title={this.props.lang.telaInicial.monitoramento}
                     width={50}
                     selected={this.state.selectedViewNumber === 1}
                     onClick={() => {
                        this.setState({ selectedViewNumber: 1, titulo:  this.props.lang.telaInicial.monitoramento });
                     }}
                  />
                  <ActionButton
                     icon={faChartBar}
                     title={this.props.lang.telaInicial.relatorios}
                     minWidth={50}
                     selected={this.state.selectedViewNumber === 2}
                     onClick={() => {
                        this.setState({ selectedViewNumber: 2, titulo: this.props.lang.telaInicial.relatorios  });
                     }}
                  />
                  <br />
               </div>
            </div>

            <ViewController
               selected={this.state.selectedViewNumber}
               v1={
                  <Fragment>
                     {this.mapa()}
                     {!this.state.mapa && (
                        <div style={{ textAlign: 'center', width: '100%', height: '70%', display: 'flex' }}>
                           <img
                              src={logoMedTrackPreto}
                              alt='logo-medtrack.png'
                              style={{ maxWidth: '50%', margin: 'auto', opacity: 0.3 }}
                           />
                        </div>
                     )}
                  </Fragment>
               }
               v2={<RelatorioDeMovimentacaoView api={this.props.api} lang={this.props.lang} />}
            ></ViewController>
         </div>
      );
   }
}

const MapImage = ({ src, x, y }) => {
   const [image] = useImage(src);
   if (image) {
      //showInfo(image.innerHTML);
   }
   return <Image image={image} x={x ? x : 0} y={y ? y : 0} />;
};

const GatewayImage = ({ point, onClick, img, index }) => {
   const [image] = useImage(img);

   return (
      <Image
         image={image}
         height={32}
         width={32}
         x={point.x ? point.x : 20}
         y={point.y ? point.y : 20}
         onClick={onClick}
         onTap={onClick}
      />
   );
};

/*
const EquipamentoImage = ({ point, onSelect, img, index }) => {
   const [image] = useImage(img);

   return (
      <Image
         key={index}
         image={image}
         height={32}
         width={32}
         x={point.x ? point.x : 20}
         y={point.y ? point.y - 16 : 20}
         onClick={onSelect}
         onTap={onSelect}
      />
   );
};
*/

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, text }) => {
   const shapeRef = React.useRef();
   const trRef = React.useRef();

   React.useEffect(() => {
      if (isSelected) {
         // we need to attach transformer manually
         trRef.current.nodes([shapeRef.current]);
         trRef.current.getLayer().batchDraw();
      }
   }, [isSelected]);

   return (
      <React.Fragment>
         <Rect onClick={onSelect} onTap={onSelect} ref={shapeRef} {...shapeProps} />
         {text && (
            <Text
               text={text}
               fontSize={12}
               fontStyle={'bold'}
               fill={'#333'}
               x={shapeProps.x + 5}
               y={shapeProps.y + 5}
            />
         )}
      </React.Fragment>
   );
};
