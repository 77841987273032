import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import Select from '../../components/Select';
import { buildQueryString, emptyStrIfNull, prepareSearchable } from '../../utils/Functions';
import EquipamentoView from './EquipamentoView';
import { LayoutParams } from '../../config/LayoutParams';
import logoMedTrackPreto from './../../contents/img/logo-002.png';
import { ActionButton } from '../../components/ActionButton';
import { faChartBar, faMap, faSync } from '@fortawesome/free-solid-svg-icons';
import { ViewController } from '../../components/ViewController';
import RelatorioDeMovimentacaoView from './RelatorioDeMovimentacaoView';
import LocalView from './LocalView';
import { Filler } from '../../components/Filler';
import { CardDeLocalDeEquipamento } from './CardDeLocalDeEquipamento';
import ListaDeMonitoradosPorLocalView from './ListaDeMonitoradosPorLocalView';

export default class HomeEmpresaMedTrackSemMapa extends Component {
   constructor(props) {
      super(props);
      this.state = {
         titulo: props.lang.telaInicial.monitoramento,
         selectedViewNumber: 1,
         locais: [],
         mostrarItens: false,
         localSelecionado: null,
      };
   }

   componentDidMount() {
      this.consultarLocais();
   }

   consultarLocais = () => {
      this.props.api
         .getAll('/local' + emptyStrIfNull(this.state.local ? '?id=' + this.state.local.id : null))
         .then((result) => {
            this.setState({ locais: result.items });
         });
   };

   dados = () => {
      return (
         <div>
            <Filler height={5} />
            <Container fluid>
               <Row>
                  <Col style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2 }}>
                     <Select
                        name={'equipamento'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        placeholder={this.props.lang.telaInicial.equipamento}
                        onSelect={(i) => {
                           this.setState(
                              {
                                 equipamento: i,
                                 local: i ? (i.local ? i.local : { id: -1 }) : this.state.local,
                                 locais: i ? (i.local ? [i.local] : []) : this.state.locais,
                              },
                              () => this.consultarLocais()
                           );
                        }}
                        formularioPadrao={(select) => {
                           return <EquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/equipamento/fast' +
                           buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </Col>

                  {!this.state.equipamento && (
                     <Col style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2 }}>
                        <Select
                           name={'local'}
                           defaultValue={this.state.local}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nome}
                           placeholder={this.props.lang.telaInicial.local}
                           onSelect={(i) => {
                              this.setState({ local: i }, () => this.consultarLocais());
                           }}
                           formularioPadrao={(select) => {
                              return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                        />
                     </Col>
                  )}

                  <Col style={{ paddingLeft: 2, marginRight: 2, marginLeft: 2, paddingRight: 2, maxWidth: 50 }}>
                     <div style={{ paddingLeft: 3, paddingTop: 0, minWidth: 38 }}>
                        <ActionButton
                           light
                           circle
                           icon={faSync}
                           height={38}
                           title={this.props.lang.telaInicial.consultar}
                           onClick={() => {
                              this.consultarLocais();
                           }}
                        />
                     </div>
                  </Col>
               </Row>
               <Row>
                  <CardDeLocalDeEquipamento
                     locais={this.state.locais}
                     lang={this.props.lang}
                     api={this.props.api}
                     setLocal={(item) => {
                        this.setState({
                           localSelecionado: item,
                           mostrarItens: item ? true : false,
                        });
                     }}
                  />
               </Row>
               {this.state.equipamento && this.state.locais && this.state.locais.length === 0 && (
                  <Row>
                     <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: 12 }}>
                        <span>Equipamento não localizado.</span>
                     </div>
                  </Row>
               )}
            </Container>
         </div>
      );
   };

   listarMonitoradosDoLocalSelecionado = () => {
      return (
         this.state.localSelecionado && (
            <Modal
               show={this.state.mostrarItens}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ mostrarItens: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <ListaDeMonitoradosPorLocalView
                     lang={this.props.lang}
                     api={this.props.api}
                     localId={this.state.localSelecionado.id}
                     titulo={this.state.localSelecionado.nome}
                     aoFechar={() => {
                        this.setState({ mostrarItens: false });
                     }}
                  />
               </Modal.Body>
            </Modal>
         )
      );
   };

   render() {
      return (
         <div
            id='div-home-empresa-v2'
            style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}
         >
            {!this.state.mostrarFiltros && false && (
               <div style={{ textAlign: 'center', height: '100%', position: 'fixed', display: 'flex', zIndex: -1 }}>
                  <img alt='logo' src={logoMedTrackPreto} style={{ maxWidth: '50%', margin: 'auto', opacity: 0.3 }} />
               </div>
            )}

            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: '5px solid ' + LayoutParams.colors.corSecundaria,
               }}
            >
               <div
                  style={{
                     paddingTop: 0,
                     paddingLeft: 10,
                     paddingRight: 0,
                     height: 45,
                     display: 'flex',
                     flexDirection: 'row',
                  }}
               >
                  <div
                     style={{
                        display: 'table-cell',
                        width: '100%',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{this.state.titulo}</span>
                  </div>

                  <ActionButton
                     icon={faMap}
                     title={this.props.lang.telaInicial.monitoramento}
                     width={50}
                     selected={this.state.selectedViewNumber === 1}
                     onClick={() => {
                        this.setState({ selectedViewNumber: 1, titulo: this.props.lang.telaInicial.monitoramento });
                     }}
                  />
                  <ActionButton
                     icon={faChartBar}
                     title={this.props.lang.telaInicial.relatorios}
                     minWidth={50}
                     selected={this.state.selectedViewNumber === 2}
                     onClick={() => {
                        this.setState({ selectedViewNumber: 2, titulo: this.props.lang.telaInicial.relatorios });
                     }}
                  />
                  <br />
               </div>
            </div>

            <ViewController
               selected={this.state.selectedViewNumber}
               v1={
                  <Fragment>
                     {this.dados()}
                     {!this.state.dados && (
                        <div style={{ textAlign: 'center', width: '100%', height: '70%', display: 'flex' }}>
                           <img
                              src={logoMedTrackPreto}
                              alt='logo-medtrack.png'
                              style={{ maxWidth: '50%', margin: 'auto', opacity: 0.3 }}
                           />
                        </div>
                     )}
                  </Fragment>
               }
               v2={<RelatorioDeMovimentacaoView api={this.props.api} lang={this.props.lang} />}
            ></ViewController>

            {this.listarMonitoradosDoLocalSelecionado()}
         </div>
      );
   }
}
