import React, { Fragment } from 'react';
import { LayoutParams } from './../../config/LayoutParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { buildQueryString, dateTimeToString, isNumeric, updateState } from './../../utils/Functions';
import { Form } from 'react-bootstrap';
import Label from '../../components/Label';
import IntegerInput from '../../components/IntegerInput';
import Line from '../../components/Line';
import { Filler } from '../../components/Filler';
import logo from './../../contents/img/logo-001.png';
import Button from '../../components/Button';
import { showError } from '../../components/Messages';
import CheckBox from '../../components/CheckBox';

let LANG = {};

export default class NotificacaoDeAlteracaoDeLocalView extends React.Component {
   constructor(props) {
      super(props);
      LANG = props.lang;
      let horasAtras = localStorage.getItem('filtro_alerta_horas_atras');
      let enviarNotificacoes = localStorage.getItem('alerta_enviar_notificacoes');
      if (horasAtras && isNumeric(horasAtras + 0)) {
         horasAtras = parseInt(horasAtras);
      } else {
         horasAtras = 12;
      }

      enviarNotificacoes = enviarNotificacoes === 'true';

      this.state = {
         mostrarFiltros: false,
         horasAtras: horasAtras,
         enviarNotificacoes: enviarNotificacoes,
         itens: [],
      };
      this.primeiraConsulta = true;
   }

   componentDidMount = () => {
      this.atualizar();
      clearInterval(this.refreshNotificacaoIntervalId);

      this.refreshNotificacaoIntervalId = setInterval(() => {
         if (!this.state.horasAtras || this.state.mostrarFiltros) {
            return;
         }
         this.atualizar();
      }, 5000);
   };

   componentWillUnmount = () => {
      clearInterval(this.refreshNotificacaoIntervalId);
   };

   habilitarNotificacoes = () => {
      let self = this;
      if ('Notification' in window) {
         if (Notification.permission === 'denied') {
            // Otherwise, we need to ask the user for permission
            Notification.requestPermission().then(function (permission) {
               // If the user accepts, let's create a notification
               if (permission === 'granted') {
                  let notification = new Notification('Informação', {
                     body: 'Notificações habilitadas!',
                     icon: 'https://mtrack.com.br/favicon.png',
                  });

                  // Add click listener that will perform a window focus
                  notification.onclick = function (x) {
                     window.focus();
                     this.close();
                  };

                  self.notificacaoSonora();

                  self.setState({ enviarNotificacoes: true });
                  localStorage.setItem('alerta_enviar_notificacoes', true);
               } else {
                  showError('O usuário não permitiu notificações.');
               }
            });
         } else {
            Notification.requestPermission().then(function (permission) {
               let notification = new Notification('Informação', {
                  body: 'Notificações habilitadas!',
                  icon: 'https://mtrack.com.br/favicon.png',
               });

               // Add click listener that will perform a window focus
               notification.onclick = function (x) {
                  window.focus();
                  this.close();
               };
               self.notificacaoSonora();

               self.setState({ enviarNotificacoes: true });
               localStorage.setItem('alerta_enviar_notificacoes', true);
            });
         }
      } else {
         showError('Este navegador não suporta notificações.');
      }
   };

   desabilitarNotificacoes = () => {
      this.setState({ enviarNotificacoes: false });
      localStorage.setItem('alerta_enviar_notificacoes', false);
   };

   notificacar = () => {
      if (this.state.enviarNotificacoes) {
         this.notificacaoSonora();
         window.postMessage(
            {
               action: 'focus',
               sender: 'medtrack',
               title: 'MedTrack',
               text: 'Novo alerta de movimentação!',
            },
            '*' // cross-origin
         );
      }
   };

   notificacaoSonora = () => {
      try {
         var audio = new Audio('https://mtrack.com.br/sound-2.wav');
         audio.play();
      } catch (e) {}
   };

   atualizar = () => {
      let filtro = {
         //ultimoId: this.state.ultimoId,
         horasAtras: this.state.horasAtras,
      };

      let query = buildQueryString(null, null, 'id', filtro);

      this.props.api
         .get('/notificacaodealteracaodelocal' + query, false, '', false, false)
         .then((result) => {
            let novosItens = result.items.filter((i) => this.state.itens.filter((j) => j.id === i.id).length === 0);
            updateState(this, (state) => {
               state.itens = result.items;
            }).then(() => {
               if (novosItens.length > 0 && !this.primeiraConsulta) {
                  this.notificacar();
               }
               this.primeiraConsulta = false;
            });
         })
         .catch(() => {});
   };

   render() {
      let tela = LANG.telaDeNotificacaoDeAlteracaoDeLocalView;

      return (
         <Fragment>
            <div style={{ textAlign: 'center', height: '100%', position: 'fixed', display: 'flex', zIndex: -1 }}>
               <img alt='logo' src={logo} style={{ maxWidth: '50%', margin: 'auto', opacity: 0.3 }} />
            </div>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: LayoutParams.colors.corSecundaria,
               }}
            >
               <div
                  style={{
                     paddingTop: 0,
                     paddingLeft: 10,
                     paddingRight: 10,
                     height: 45,
                     display: 'flex',
                     flexDirection: 'row',
                  }}
               >
                  <div
                     style={{
                        display: 'table-cell',
                        width: '100%',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{tela.titulo}</span>
                  </div>
                  <div
                     style={{
                        display: 'table-cell',
                        width: 50,
                        fontSize: 22,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        paddingTop: 8,
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        this.setState({ mostrarFiltros: !this.state.mostrarFiltros });
                     }}
                  >
                     <FontAwesomeIcon icon={faCog} />
                  </div>
                  <br />
               </div>
            </div>

            {this.state.mostrarFiltros && (
               <Fragment>
                  <div
                     style={{
                        padding: '10px 0 0 10px',
                     }}
                  >
                     <strong>Configurações</strong>
                     <br />
                     <Filler height={8} />
                     <div
                        style={{
                           width: 150,
                        }}
                     >
                        <Form.Group>
                           <Label>{tela.horasAtras}</Label>
                           <IntegerInput
                              defaultValue={this.state.horasAtras}
                              onChange={(e) => this.setState({ horasAtrasTemp: e.floatValue })}
                           />
                        </Form.Group>
                     </div>

                     <div style={{}}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                           <div style={{ display: 'table-cell', width: 20, fontSize: 16, marginRight: 4 }}>
                              <CheckBox
                                 defaultChecked={this.state.enviarNotificacoes}
                                 onChange={(checked) => {
                                    this.setState({ enviarNotificacoesTemp: checked });
                                 }}
                              />
                              <br />
                           </div>
                           <div style={{ display: 'table-cell', width: '100%' }}>{'Mostrar notificações'}</div>
                        </div>
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ display: 'table-cell', width: 150 }}>
                           <Button
                              text={'OK'}
                              onClick={() => {
                                 localStorage.setItem('filtro_alerta_horas_atras', this.state.horasAtrasTemp);
                                 this.setState({ horasAtras: this.state.horasAtrasTemp, mostrarFiltros: false });
                                 if (this.state.enviarNotificacoesTemp) {
                                    this.habilitarNotificacoes();
                                 } else {
                                    this.desabilitarNotificacoes();
                                 }
                              }}
                           />
                        </div>
                        <div style={{ display: 'table-cell', width: 150, marginLeft: 8 }}>
                           <Button
                              text={this.props.lang.cancelar}
                              onClick={() => {
                                 this.setState({ mostrarFiltros: false });
                              }}
                           />
                        </div>
                     </div>
                     <br />
                  </div>
                  <Line />
               </Fragment>
            )}
            <div
               style={{
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
               }}
            >
               {!this.state.mostrarFiltros &&
                  this.state.itens.map((item, index) => {
                     return <Notificacao key={index} item={item} />;
                  })}
            </div>
         </Fragment>
      );
   }
}

const Notificacao = ({ item }) => {
   let tela = LANG.telaDeNotificacaoDeAlteracaoDeLocalView;
   return (
      <div
         style={{
            border: 'solid 1px ' + LayoutParams.colors.fundoCinza,
            borderRadius: 8,
            padding: 5,
            //backgroundColor: '#ffa07a40',
            flexGrow: 1,
            maxWidth: 300,
            margin: 5,
         }}
      >
         <div style={{}}>
            <strong>{tela.equipamento}:&nbsp;</strong>
            <span>{item.equipamento.nome}</span>
            <br />
            <strong>{tela.area}:&nbsp;</strong>
            <span>{item.local.nome}</span>
            <br />
            <strong>{tela.local}:&nbsp;</strong>
            <span>{item.mapa.nome}</span>
            <br />
            <strong>{tela.patrimonio}:&nbsp;</strong>
            <span>{item.equipamento.patrimonio}</span>
            <br />
            <strong>{tela.data}:&nbsp;</strong>
            <span>{dateTimeToString(item.data, this.props.lang)}</span>
         </div>
         {/* <Line marginTop={3} />
         <Filler height={8} />
         <div style={{}}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', width: 20, fontSize: 16, marginRight: 4 }}>
                  <CheckBox />
               </div>
               <div style={{ display: 'table-cell', width: '100%' }}>
                  {LANG.telaDeNotificacaoDeAlteracaoDeLocalView.resolvido}
               </div>
            </div>
         </div> */}
      </div>
   );
};
