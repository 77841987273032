const getApiUrl = () => {
    let url = "https://api.mtrack.com.br";

    // return url;    

    if (process && process.env) {

        if (process.env.NODE_ENV === 'development') {
            url = 'https://192.168.10.14:5001';
        }

        if (process.env.REACT_APP_WEBSITE_NAME && process.env.REACT_APP_WEBSITE_NAME.trim() === "tecnometrics-pre-prod") {
            url = 'https://api.mtrack.com.br:8080';
        }
    }    

    if (!url) {
       throw new Error('Url da api não foi definida.');
    }

    return url;
};

export const ConnectionParams = {
    apiUrl: getApiUrl(),
};