import React, { Component } from 'react';
import { dateTimeToString } from '../../utils/Functions';
import ListaPadrao from '../ListaPadrao';

export default class HistoricoDeMonitoramentoView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            monitorado: this.props.idDoEquipamento,
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         {},
         { titulo: this.props.lang.historicoDeMonitoramento.data, width: '15%', orderby: 'Data' },
         { titulo: this.props.lang.historicoDeMonitoramento.predio, width: '35%', orderby: 'AreaAtual.Mapa.Predio.Nome' },
         { titulo: this.props.lang.historicoDeMonitoramento.andar, width: '10%', orderby: 'AreaAtual.Local.Andar' },
         { titulo: this.props.lang.historicoDeMonitoramento.local, width: '30%', orderby: 'AreaAtual.Local.Nome' },
         { titulo: 'Tag', width: '20%', orderby: 'Tag' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         null,
         dateTimeToString(item.data, this.props.lang),
         item.nomeDoPredioAtual,
         item.andarDoLocalAtual,
         item.nomeDoLocalAtual,
         item.tag?.mac,
      ];
   };

   render() {
      return (
         <ListaPadrao
            titulo={this.props.titulo}
            url={'/historicodemonitoramento'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getFiltro={this.getFiltro}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            aoFechar={this.props.aoFechar}
            getFormState={() => this.state}
            estruturaPadrao={true}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
