import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import LocalController from '../../controllers/Empresa/LocalController';
import { buildQueryString, updateState, prepareSearchable } from '../../utils/Functions';
import Select from '../../components/Select';
import { RgbaPicker } from 'react-rgba-color-picker';
import PredioView from './PredioView';
import IntegerInput from '../../components/IntegerInput';

export default class LocalView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarCampoCor: true, mostrarColorPicker: true };
      this.controller = new LocalController(this);
   }

   getCor = (cor) => {
      let result = {
         r: 255,
         g: 255,
         b: 255,
         a: 1,
      };
      if (cor) {
         cor = cor.replace('RGBA(', '');
         cor = cor.replace(')', '');
         cor = cor.replace(' ', '');
         let corParts = cor.split(',');
         result = {
            r: corParts[0],
            g: corParts[1],
            b: corParts[2],
            a: corParts[3],
         };
      }
      return result;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeLocal;
      return (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.situacaoDeLocal.ativo },
                           { id: 2, descricao: lang.situacaoDeLocal.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.predio}</Label>
                     <Select
                        name={'predio'}
                        defaultValue={this.state.itemSelecionado.predio}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.predio = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <PredioView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor={'#ffff'}
                        getFilterUrl={(text) =>
                           '/predio' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{'Andar'}</Label>
                     <IntegerInput
                        defaultValue={this.state.itemSelecionado.andar}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.andar = e.floatValue;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.descricao}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.descricao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.descricao = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col style={{ maxWidth: 300 }}>
                  <FormGroup>
                     <Label>{tela.cor}</Label>
                     {this.state.mostrarCampoCor && (
                        <TextInput
                           defaultValue={this.state.itemSelecionado.cor}
                           onChange={(e) => {
                              if (this.state.mostrarCampoCor) {
                                 this.setState({ mostrarColorPicker: false }, () => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.cor = e.target.value;
                                    }).then(() => this.setState({ mostrarColorPicker: true }));
                                 });
                              }
                           }}
                        />
                     )}
                     {this.state.mostrarColorPicker && this.state.itemSelecionado && (
                        <RgbaPicker
                           color={this.getCor(this.state.itemSelecionado.cor)}
                           onChange={(c) => {
                              if (this.state.mostrarColorPicker) {
                                 this.setState({ mostrarCampoCor: false }, () => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.cor =
                                          'RGBA(' + c.r + ',' + c.g + ',' + c.b + ',' + c.a + ')';
                                    }).then(() => this.setState({ mostrarCampoCor: true }));
                                 });
                              }
                           }}
                        />
                     )}
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

   getRandomArbitrary = (min, max) => {
      return Math.random() * (max - min) + min;
   };

   definirValoresPadrao = (item) => {
      let cor =
         'RGBA(' +
         Math.trunc(this.getRandomArbitrary(1, 255)) +
         ',' +
         Math.trunc(this.getRandomArbitrary(1, 255)) +
         ',' +
         Math.trunc(this.getRandomArbitrary(1, 255)) +
         ',0.3)';
      item.cor = cor;
      return item;
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeLocal.titulo}
            url={'/local'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'predio, nome'}
            permissoes={[1421, 1422, 1423, 1424]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            definirValoresPadrao={this.definirValoresPadrao}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            form={this.props.form}
            itemVazio={{
               situacao: { id: 1 },
            }}
         />
      );
   }
}
