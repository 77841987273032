import { Row, Col, FormGroup, Container } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import Label from '../../components/Label';
import {
   dateTimeToString,
   emptyStrIfNull,
   objectToQueryString,
   prepareSearchable,
   updateState,
} from '../../utils/Functions';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Button from '../../components/Button';
import { Filler } from '../../components/Filler';
import TextInput from '../../components/TextInput';
import { showError } from '../../components/Messages';

const STATUS_DA_TELA = {
   Carregando1: 1,
   Carregando2: 2,
   Carregando3: 3,
   Carregando4: 4,
   Carregado: 9,
   Erro: -1,
};

let LANG = {};

export default class RelatorioDeUltimaLocalizacaoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         statusDaTela: STATUS_DA_TELA.Carregado,
         filtro1: null,
         filtro2: null,
         filtro3: null,
      };
      LANG = props.lang;
   }

   componentWillUnmount = () => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
   };

   iniciarProcessamento = () => {
      this.atualizarEtapadoCarregamentoId = setInterval(() => {
         if (this.state.statusDaTela === STATUS_DA_TELA.Carregando1) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando2 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando2) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando3 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando3) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando4 });
         }
      }, 10000);

      this.setState({
         mensagemDeErro: null,
         statusDaTela: STATUS_DA_TELA.Carregando1,
      });
   };

   finalizarProcessamentoComSucesso = () => {
      this.setState({
         mensagemDeErro: null,
         statusDaTela: STATUS_DA_TELA.Carregado,
      });
   };

   finalizarProcessamentoComErro = (e) => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
      this.setState({
         mensagemDeErro: this.props.api.getErrorMessage(e),
         statusDaTela: STATUS_DA_TELA.Erro,
      });
   };

   finalizarProcessamentoVazio = () => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
      this.setState({
         mensagemDeErro: LANG.relatorioDeMovimentacao.nenhumRegistroEncontrado,
         statusDaTela: STATUS_DA_TELA.Erro,
      });
   };

   consultar = () => {
      this.setState({
         dados: null,
         filtro1Realizado: this.state.filtro1,
         filtro2Realizado: this.state.filtro2,
         filtro3Realizado: this.state.filtro3,
      });

      return new Promise((resolve, reject) => {
         if (!this.state.filtro1) {
            showError(LANG.relatorioDeMovimentacao.informePeloMenosUmFiltro);
            reject();
            return;
         }

         if (this.state.filtro1.length < 3) {
            showError(LANG.relatorioDeMovimentacao.informePeloMenosTresCaracteres);
            reject();
            return;
         }

         this.iniciarProcessamento();

         this.props.api
            .get(
               '/equipamento/relatoriodeultimalocalizacaodeequipamento?' +
                  objectToQueryString({
                     filtro1: this.state.filtro1 ? '%' + prepareSearchable(this.state.filtro1) + '%' : null,
                     filtro2: this.state.filtro2 ? '%' + prepareSearchable(this.state.filtro2) + '%' : null,
                     filtro3: this.state.filtro3 ? '%' + prepareSearchable(this.state.filtro3) + '%' : null,
                  }),
               false,
               null,
               false,
               false
            )
            .then((result) => {
               this.setState(
                  {
                     dados: result,
                  },
                  () => {
                     if (result.itens && result.itens.length > 0) {
                        this.finalizarProcessamentoComSucesso();
                     } else {
                        this.finalizarProcessamentoVazio();
                     }
                     resolve();
                  }
               );
            })
            .catch((e) => {
               this.finalizarProcessamentoComErro(e);
               reject(e);
            });
      });
   };

   render() {
      let result = (
         <Fragment>
            <Container
               style={{
                  overflowY: 'auto',
                  maxHeight: '100%',
                  minWidth: '100%',
                  margin: 0,
                  paddingTop: 5,
               }}
            >
               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.filtro1}</Label>
                        <TextInput
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.filtro1 = e.target.value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>

                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.filtro2}</Label>
                        <TextInput
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.filtro2 = e.target.value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>

                  <Col>
                     <FormGroup>
                        <Label>{LANG.relatorioDeMovimentacao.filtro3}</Label>
                        <TextInput
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.filtro3 = e.target.value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <ReactToPrint content={() => this.reportComponentRef}>
                        <PrintContextConsumer>
                           {({ handlePrint }) => (
                              <Row>
                                 <Col style={{ maxWidth: 180 }}>
                                    <Button
                                       onClickAsync={() => {
                                          return new Promise((resolve, reject) => {
                                             this.consultar()
                                                .then(() => {
                                                   resolve();
                                                })
                                                .catch(reject);
                                          });
                                       }}
                                       inProgressText={LANG.relatorioDeMovimentacao.gerando}
                                       text={LANG.relatorioDeMovimentacao.gerar}
                                    />
                                 </Col>
                                 {this.state.dados && this.state.dados.itens && this.state.dados.itens.length > 0 && (
                                    <Col style={{ maxWidth: 180, paddingLeft: 0 }}>
                                       <Button
                                          onClick={() => {
                                             handlePrint();
                                          }}
                                          text={LANG.relatorioDeMovimentacao.imprimir}
                                       />
                                    </Col>
                                 )}
                              </Row>
                           )}
                        </PrintContextConsumer>
                     </ReactToPrint>
                  </Col>
               </Row>

               <br />
               <br />

               {this.state.statusDaTela >= STATUS_DA_TELA.Carregando1 &&
                  this.state.statusDaTela <= STATUS_DA_TELA.Carregando4 && (
                     <div
                        style={{
                           display: 'flex',
                           width: '100%',
                           flexDirection: 'column',
                        }}
                     >
                        <div style={{ margin: 'auto', paddingTop: 30 }}>
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando1 && (
                              <span>{LANG.relatorioDeMovimentacao.consultandoOHistoricoDeMovimentacao}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando2 && (
                              <span>{LANG.relatorioDeMovimentacao.baixandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando3 && (
                              <span>{LANG.relatorioDeMovimentacao.processandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando4 && (
                              <span>{LANG.relatorioDeMovimentacao.preparandoExibicao}</span>
                           )}
                        </div>
                     </div>
                  )}

               {this.state.statusDaTela === STATUS_DA_TELA.Erro && (
                  <div
                     style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                     }}
                  >
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        <span>{this.state.mensagemDeErro}</span>
                     </div>
                  </div>
               )}

               {this.state.statusDaTela === STATUS_DA_TELA.Carregado && (
                  <div>
                     <div ref={(el) => (this.reportComponentRef = el)}>{this.state.dados && this.relatorio()}</div>
                     <br />
                     <br />
                  </div>
               )}
            </Container>
         </Fragment>
      );

      return result;
   }

   relatorio = () => {
      let result = null;

      if (this.state.dados && this.state.dados.itens) {
         result = (
            <React.Fragment>
               <div style={{ paddingTop: 3, fontSize: 16 }}>
                  <strong>{LANG.relatorioDeMovimentacao.ultimaLocalizacaoDeEquipamento}</strong>
               </div>

               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>
                        {LANG.relatorioDeMovimentacao.filtro1 + ': ' + emptyStrIfNull(this.state.filtro1Realizado)}
                     </span>
                  </div>
               </div>

               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>
                        {LANG.relatorioDeMovimentacao.filtro2 + ': ' + emptyStrIfNull(this.state.filtro2Realizado)}
                     </span>
                  </div>
               </div>

               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>{LANG.relatorioDeMovimentacao + ': ' + emptyStrIfNull(this.state.filtro3Realizado)}</span>
                  </div>
               </div>

               <Filler height={5} />

               <LinhaDeCabecalho>
                  <Coluna texto={LANG.relatorioDeMovimentacao.nome} largura={'25%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.numeroDeSerie} largura={'15%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.patrimonio} largura={'10%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.predio} largura={'15%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.local} largura={'15%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.andar} largura={'10%'} />
                  <Coluna texto={LANG.relatorioDeMovimentacao.data} largura={'10%'} />
               </LinhaDeCabecalho>

               {this.state.dados.itens.map((item, index) => {
                  return (
                     <div style={{ fontSize: 10 }}>
                        <div key={index} style={{ border: '1px solid' }}>
                           <Linha>
                              <Coluna texto={item.nomeDoEquipamento} largura={'25%'} />
                              <Coluna texto={item.numeroDeSerie} largura={'15%'} />
                              <Coluna texto={item.patrimonio} largura={'10%'} />
                              <Coluna texto={item.nomeDoPredio} largura={'15%'} />
                              <Coluna texto={item.nomeDoLocal} largura={'15%'} />
                              <Coluna texto={item.andar} largura={'8%'} />
                              <Coluna texto={dateTimeToString(item.data, this.props.lang)} largura={'12%'} />
                           </Linha>
                        </div>
                     </div>
                  );
               })}
            </React.Fragment>
         );
      }

      return result;
   };
}

class Linha extends React.Component {
   render() {
      return (
         <div className={'linha-relatorio'} style={{ display: 'flex', flexDirection: 'row' }}>
            {this.props.children}
         </div>
      );
   }
}

class LinhaDeCabecalho extends React.Component {
   render() {
      return (
         <div
            className={'linha-relatorio'}
            style={{
               display: 'flex',
               flexDirection: 'row',
               fontWeight: 'bold',
               border: '1px solid',
               backgroundColor: 'lightgray',
            }}
         >
            {this.props.children}
         </div>
      );
   }
}

const Coluna = ({ texto, largura }) => {
   return (
      <div style={{ display: 'table-cell', width: largura }}>
         <span>{texto}</span>
      </div>
   );
};
