import React from 'react';
import { Col } from 'react-bootstrap';

const cor1 = '#ffffff';
const cor2 = '#999';

export const CardDeLocalDeEquipamento = ({ locais, setLocal }) => {
   let result = !locais
      ? null
      : locais.map((item, index) => {
           return (
              <Col
                 key={index}
                 style={{
                    backgroundColor: cor1,
                    minWidth: 210,
                    maxWidth: 210,
                    borderRadius: 5,
                    borderWidth: 2,
                    margin: 5,
                    borderColor: cor2,
                    borderStyle: 'solid',
                    color: '#666',
                    padding: 0,
                 }}
              >
                 <div
                    style={{
                       display: 'flex',
                       flexDirection: 'column',
                       backgroundColor: cor2,
                       cursor: 'pointer',
                    }}
                    onClick={() => {
                       setLocal(item);
                    }}
                 >
                    <div
                       style={{
                          display: 'table-cell',
                          width: '100%',
                          backgroundColor: cor1,
                          borderRadius: 3,
                       }}
                    >
                       <div style={{ fontSize: 14, paddingLeft: 4, paddingRight: 4 }} onClick={() => {}}>
                          <span>{item.nome}</span>
                       </div>
                       {item.predio && (
                          <div style={{ fontSize: 11, paddingLeft: 4, paddingRight: 4 }} onClick={() => {}}>
                             <span>{item.predio.nome}</span>
                          </div>
                       )}
                    </div>
                 </div>
              </Col>
           );
        });

   return result;
};
