import { Row, Col, FormGroup, Card, Form, Tab, Tabs } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import GatewayController from '../../controllers/Empresa/GatewayController';
import {
   updateState,
   dateTimeToString,
   buildQueryString,
   formatDate,
   numberToString2,
   prepareSearchable,
} from '../../utils/Functions';
import Select from '../../components/Select';
import { Filler } from '../../components/Filler';
import IntegerInput from '../../components/IntegerInput';
import DateInput from '../../components/DateInput';
import ListaPadrao from '../ListaPadrao';
import styled from 'styled-components';
import { LayoutParams } from '../../config/LayoutParams';
import EquipamentoView from './EquipamentoView';
import { showError } from '../../components/Messages';
import CheckBox from '../../components/CheckBox';
import TimeInput from '../../components/TimeInput';
import SubCadastro from '../../components/SubCadastro';
import ServicoDeNotificacaoView from './ServicoDeNotificacaoView';
import LocalView from './LocalView';

let LANG = {};
let TELA = {};

export default class GatewayView extends Component {
   constructor(props) {
      super(props);
      LANG = props.lang;
      TELA = props.lang.telaDeCadastroDeGateway;
      this.state = {
         mostrarAcessos: true,
         abaSelecionada: 'pacotes',
         formPacotes: {},
         formLogs: {},
         formHistorico: {},
         dataInicialDeFiltroDeLog: new Date(),
         horaInicialDeFiltroDeLog: '00:00',
         dataFinalDeFiltroDeLog: new Date(),
         horaFinalDeFiltroDeLog: '23:59',
      };
      this.controller = new GatewayController(this);
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{LANG.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{TELA.mac}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.mac}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.mac = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{TELA.patrimonio}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.patrimonio}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.patrimonio = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{TELA.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: LANG.situacaoDeGateway.ativo },
                           { id: 2, descricao: LANG.situacaoDeGateway.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.id && (
               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{TELA.local}</Label>
                        <Select
                           name={'localAtual'}
                           defaultValue={this.state.itemSelecionado.local}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nome}
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.local = i;
                              });
                           }}
                           formularioPadrao={(select) => {
                              return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                           }}
                           noDropDown={true}
                           readOnlyColor={'#ffff'}
                           getFilterUrl={(text) =>
                              '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                           readOnly={!this.state.itemSelecionado.podeAlterarLocal}
                        />
                     </FormGroup>
                  </Col>
               </Row>
            )}

            <Card>
               <Card.Body style={{ padding: 6 }}>
                  <strong style={{ padding: 3 }}>{TELA.outrasConfiguracoes}</strong>
                  <Filler height={10} />
                  <Row>
                     <Col>
                        <CheckBox
                           defaultChecked={this.state.itemSelecionado.cadastrarTagsProximas}
                           onChange={(checked) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.cadastrarTagsProximas = checked;
                              });
                           }}
                           label={TELA.cadastrarTagsProximas}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <CheckBox
                           defaultChecked={this.state.itemSelecionado.indicadorDeTagsEmManutencao}
                           onChange={(checked) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.indicadorDeTagsEmManutencao = checked;
                              });
                           }}
                           label={TELA.indicadorDeManutencao}
                        />
                     </Col>
                  </Row>
                  <Filler height={8} />
                  <Row>
                     <Col sm={12} md={12} lg={6} xl={6}>
                        <FormGroup>
                           <Label>{TELA.distanciaMaxima}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.distanciaMaxima}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.distanciaMaxima = e.floatValue;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Card.Body>
            </Card>

            <br />

            <SubCadastro
               titulo={TELA.notificacoesDeGatewayOffline}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.notificacoesDeGatewayOffline}
               lang={this.props.lang}
               formularioPadrao={(form) => {
                  return <ServicoDeNotificacaoView api={this.props.api} form={form} lang={this.props.lang} />;
               }}
               aoSelecionar={(item) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.notificacoesDeGatewayOffline) {
                           state.itemSelecionado.notificacoesDeGatewayOffline = [];
                        }
                        state.itemSelecionado.notificacoesDeGatewayOffline.push(item);
                     }).then(() => resolve());                     
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.notificacoesDeGatewayOffline.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.nome];
               }}
            />

            <br />

            {this.state.itemSelecionado && this.state.itemSelecionado.id && (
               <div style={{ border: '1px solid rgba(0,0,0,.125)', borderRadius: '.25rem' }}>
                  <div style={{ padding: 10 }}>
                     <Row>
                        <Col sm={3} md={3} lg={3}>
                           <strong style={{ padding: 3 }}>Comunicação</strong>
                        </Col>
                     </Row>
                     <Filler height={10} />
                     <Row>
                        <Col sm={4} md={4} lg={4}>
                           <FormGroup>
                              <Label>Data da última leitura</Label>
                              <TextInput
                                 defaultValue={dateTimeToString(this.state.itemSelecionado.dataDaUltimaLeitura)}
                                 readOnly={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>

                  <StyledNav style={{ padding: 0 }}>
                     <Tabs variant='tabs' defaultActiveKey={this.state.abaSelecionada} style={{ color: '#444' }}>
                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'pacotes' })}
                           eventKey='pacotes'
                           title={'Pacotes'}
                        >
                           {this.pacotes()}
                        </Tab>
                        {/* <Tab onClick={() => this.setState({ abaSelecionada: 'logs' })} eventKey='logs' title={'Logs'}>
                           {this.logs()}
                        </Tab> */}
                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'historicoDeComunicacao' })}
                           eventKey='historicoDeComunicacao'
                           title={'Histórico de comunicação'}
                        >
                           {this.historicoDeComunicacao()}
                        </Tab>
                     </Tabs>
                  </StyledNav>
               </div>
            )}

            <br />
         </React.Fragment>
      );
   };

   pacotes = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/gateway/pacotes'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getTitulosDaTabela={() => {
               return [
                  {},
                  { titulo: 'Data', width: '30%', orderby: 'Data' },
                  { titulo: 'TAG', width: '35%', orderby: 'Tag' },
                  { titulo: 'Distância', width: '35%', orderby: 'Distancia' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  dateTimeToString(item.data, this.props.lang),
                  item.tag,
                  <span>{numberToString2(item.distancia)}m</span>,
               ];
            }}
            getFormState={() => this.state.formPacotes}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formPacotes = { ...state.formPacotes, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  let mac = null;

                  if (this.state.equipamento) {
                     if (!this.state.equipamento.tag) {
                        showError('Este equipamento não tem TAG associada.');
                        reject();
                        return;
                     } else {
                        mac = this.state.equipamento.tag.mac;
                     }
                  }

                  resolve({
                     gateway: this.state.itemSelecionado.mac,
                     mac: mac,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{'Equipamento'}</Label>
                              <Select
                                 name={'equipamento'}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.nome}
                                 onSelect={(i) => {
                                    this.setState({ equipamento: i });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <EquipamentoView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/equipamento' +
                                    buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   logs = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/gateway/logs'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getTitulosDaTabela={() => {
               return [
                  { titulo: this.props.lang.codigo, orderby: 'Id', className: 'codigo' },
                  { titulo: 'Data', width: '15%' },
                  { titulo: 'Log', width: '85%' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [item.id, dateTimeToString(item.date, this.props.lang), item.message];
            }}
            getFormState={() => this.state.formLogs}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formLogs = { ...state.formLogs, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  if (!this.state.dataDeFiltroDeLog) {
                     showError('Informe uma data.');
                     reject();
                     return;
                  }

                  resolve({
                     searchable: this.state.itemSelecionado.filtroDeLog,
                     gateway: this.state.itemSelecionado.mac,
                     dataInicial: formatDate(this.state.dataDeFiltroDeLog),
                     dataFinal: formatDate(this.state.dataDeFiltroDeLog),
                     action: '/receiver/moko',
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{'Data'}</Label>
                              <DateInput
                                 defaultValue={this.state.dataDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{'Filtro'}</Label>
                              <TextInput
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.filtroDeLog = e.target.value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   historicoDeComunicacao = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/gateway/historicodecomunicacao'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'Data desc'}
            getTitulosDaTabela={() => {
               return [
                  { titulo: this.props.lang.codigo, orderby: 'Id', className: 'codigo' },
                  { titulo: 'Data', width: '50%', orderby: 'Data' },
                  { titulo: 'Quantidade de pacotes', width: '50%', orderby: 'QuantidadeDePacotes' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [item.id, dateTimeToString(item.data, this.props.lang), item.quantidadeDePacotes];
            }}
            getFormState={() => this.state.formHistorico}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formHistorico = { ...state.formHistorico, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  if (!this.state.dataInicialDeFiltroDeLog) {
                     showError('Informe a data inicial.');
                     reject();
                     return;
                  }

                  if (!this.state.horaInicialDeFiltroDeLog) {
                     showError('Informe a hora inicial.');
                     reject();
                     return;
                  }

                  if (!this.state.dataFinalDeFiltroDeLog) {
                     showError('Informe a data final.');
                     reject();
                     return;
                  }

                  if (!this.state.horaFinalDeFiltroDeLog) {
                     showError('Informe a hora final.');
                     reject();
                     return;
                  }

                  let filtro = {
                     gateway: this.state.itemSelecionado.id,
                     dataInicial: formatDate(this.state.dataInicialDeFiltroDeLog),
                     horaInicial: this.state.horaInicialDeFiltroDeLog,
                     dataFinal: formatDate(this.state.dataFinalDeFiltroDeLog),
                     horaFinal: this.state.horaFinalDeFiltroDeLog,
                  };

                  resolve(filtro);
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{'Data inicial'}</Label>
                              <DateInput
                                 defaultValue={this.state.dataInicialDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataInicialDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{'Hora inicial'}</Label>
                              <TimeInput
                                 defaultValue={this.state.horaInicialDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.horaInicialDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>

                        <Col>
                           <FormGroup>
                              <Label>{'Data final'}</Label>
                              <DateInput
                                 defaultValue={this.state.dataFinalDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataFinalDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{'Hora final'}</Label>
                              <TimeInput
                                 defaultValue={this.state.horaFinalDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.horaFinalDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={TELA.titulo}
            url={'/gateway'}
            fastUrl={'/gateway/fast'}
            api={this.props.api}
            lang={LANG}
            ordenacaoPadrao={'mac'}
            permissoes={[1411, 1412, 1413, 1414]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            form={this.props.form}
            itemVazio={{
               situacao: { id: 1 },
            }}
         />
      );
   }
}

const StyledNav = styled.div`
   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
   }
`;
