import React, { Component } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Label from '../../components/Label';
import { updateState, dateTimeToString, numberToString2, replaceAll, retirarAcentos } from '../../utils/Functions';
import ListaPadrao from '../ListaPadrao';
import TextInput from '../../components/TextInput';

export default class ListaDeMonitoradosPorLocalView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            local: this.props.localId,
            searchable: this.state.searchable, 
         });
      });
   };

   renderizarFiltros = () => {
      return (
         <div style={{ maxWidth: 800 }}>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeListaDeMonitoradosPorLocal.equipamento}</Label>
                     <TextInput
                        onChange={(e) => {
                           updateState(this, (state) => {
                              let texto = e.target.value;
                              if (texto) {
                                 texto = ('' + retirarAcentos(texto)).toUpperCase();
                                 var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
                                 charsToRemove.forEach((c) => {
                                    texto = replaceAll(texto, c, '');
                                 });
                              }
                              state.searchable = texto;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   getTitulosDaTabela = () => {
      return [
         {},
         { titulo: this.props.lang.telaDeListaDeMonitoradosPorLocal.nome, width: '35%', orderBy: "nome" },
         { titulo: this.props.lang.telaDeListaDeMonitoradosPorLocal.numeroDeSerie, width: '20%', orderBy: "numeroDeSerie" },
         { titulo: this.props.lang.telaDeListaDeMonitoradosPorLocal.patrimonio, width: '15%', orderBy: "patrimonio" },
         { titulo: this.props.lang.telaDeListaDeMonitoradosPorLocal.dataDaLeitura, width: '15%', orderBy: "dataDaLeitura" },
         { titulo: this.props.lang.telaDeListaDeMonitoradosPorLocal.distancia, width: '15%', orderBy: "distancia" },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         null,
         item.nome,
         item.numeroDeSerie,
         item.patrimonio,
         dateTimeToString(item.dataDaUltimaAtualizacao, this.props.lang),
         item.distancia || item.distancia === 0 ? numberToString2(item.distancia, 2, this.props.lang) + 'm' : null,
      ];
   };

   render() {
      return (
         <ListaPadrao
            titulo={this.props.titulo}
            url={'/equipamento'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            estruturaPadrao={true}
            getFiltro={this.getFiltro}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            aoFechar={this.props.aoFechar}
            getFormState={() => this.state}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
