import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import MapaController from '../../controllers/Empresa/MapaController';
import { buildQueryString, getBase64ImageSize, updateState, prepareSearchable } from '../../utils/Functions';
import Select from '../../components/Select';
import Image from '../../components/Image';
import IntegerInput from '../../components/IntegerInput';
import SubCadastro from '../../components/SubCadastro';
import LocalView from './LocalView';
import GatewayView from './GatewayView';
import PredioView from './PredioView';

let LANG = {};
let TELA = {};

export default class MapaView extends Component {
   constructor(props) {
      super(props);
      LANG = props.lang;
      TELA = props.lang.telaDeCadastroDeMapa;
      this.state = { mostrarAcessos: true };
      this.controller = new MapaController(this);
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <div style={{ maxWidth: 800 }}>
               <Row>
                  <Col sm={3} md={3} lg={3}>
                     <FormGroup>
                        <Label>{LANG.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{TELA.nome}</Label>
                        <TextInput
                           defaultValue={this.state.itemSelecionado.nome}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.nome = e.target.value;
                              });
                           }}
                           upperCase={true}
                        />
                     </FormGroup>
                  </Col>
                  <Col sm={3} md={3} lg={3}>
                     <FormGroup>
                        <Label>{TELA.situacao}</Label>
                        <Select
                           as='select'
                           name='situacao'
                           defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                           options={[
                              { id: 1, descricao: LANG.situacaoDeMapa.ativo },
                              { id: 2, descricao: LANG.situacaoDeMapa.inativo },
                           ]}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.descricao}
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.situacao = i;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{TELA.predio}</Label>
                        <Select
                           name={'predio'}
                           defaultValue={this.state.itemSelecionado.predio}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nome}
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.predio = i;
                              });
                           }}
                           formularioPadrao={(select) => {
                              return <PredioView api={this.props.api} select={select} lang={LANG} />;
                           }}
                           noDropDown={true}
                           readOnlyColor={'#ffff'}
                           getFilterUrl={(text) =>
                              '/predio' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                        />
                     </FormGroup>
                  </Col>
                  <Col sm={3} md={3} lg={3}>
                     <FormGroup>
                        <Label>{TELA.andar}</Label>
                        <IntegerInput
                           defaultValue={this.state.itemSelecionado.andar}
                           onChange={(v) => {
                              updateState(this, (state) => {                                 
                                 state.itemSelecionado.andar = v.floatValue;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <SubCadastro
                  titulo={TELA.locais}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.locais}
                  lang={LANG}
                  formularioPadrao={(form) => {
                     return <LocalView api={this.props.api} form={form} lang={this.props.lang} />;
                  }}
                  aoSelecionar={(item) => {
                     return new Promise((resolve, reject) => {
                        updateState(this, (state) => {
                           if (!state.itemSelecionado.locais) {
                              state.itemSelecionado.locais = [];
                           }
                           state.itemSelecionado.locais.push({
                              local: item,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.locais.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: 'Local', width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.local.nome];
                  }}
               />

               <br />

               <SubCadastro
                  titulo={TELA.gateways}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.gateways}
                  lang={LANG}
                  formularioPadrao={(form) => {
                     return <GatewayView api={this.props.api} form={form} lang={this.props.lang} />;
                  }}
                  aoSelecionar={(item) => {
                     return new Promise((resolve, reject) => {
                        updateState(this, (state) => {
                           if (!state.itemSelecionado.gateways) {
                              state.itemSelecionado.gateways = [];
                           }
                           state.itemSelecionado.gateways.push({
                              gateway: item,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.gateways.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: 'Gateway', width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.gateway.mac];
                  }}
               />
            </div>
            <br />

            {TELA.planta}
            <div style={{ overflow: 'auto' }}>
               <Image
                  lang={LANG}
                  api={this.props.api}
                  image={this.state.itemSelecionado.imagem}
                  width={'100%'}
                  onChange={(image) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.imagem = image;
                     });
                  }}
                  onLoad={(base64) => {
                     if (base64) {
                        getBase64ImageSize(base64).then((i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.altura = i.height;
                              state.itemSelecionado.largura = i.width;
                           });
                        });
                     } else {
                        updateState(this, (state) => {
                           state.itemSelecionado.altura = null;
                           state.itemSelecionado.largura = null;
                        });
                     }
                  }}
               />
            </div>

            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={LANG.telaDeCadastroDeMapa.titulo}
            url={'/mapa'}
            api={this.props.api}
            lang={LANG}
            ordenacaoPadrao={'Predio.Nome, Nome'}
            permissoes={[1451, 1452, 1453, 1454]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            filtroExtra={this.props.filtroExtra}
            maxWidth={-1}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
            }}
         />
      );
   }
}
