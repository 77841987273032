import React, { Component, Fragment } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import { dateTimeToString, formatDate, numberToString, objectToQueryString, updateState } from '../../utils/Functions';
import Button from '../../components/Button';
import { showError } from '../../components/Messages';
import TimeInput from '../../components/TimeInput';
import { Filler } from '../../components/Filler';
import Line from '../../components/Line';
import { Doughnut } from 'react-chartjs-2';
import ReportHeader from '../../components/ReportHeader';
 import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);
 

const STATUS_DA_TELA = {
   Carregando: 1,
   Carregado: 2,
   Erro: 3,
};

export default class SaudeDoServicoView extends Component {
   constructor(props) {
      super(props);
      // LANG = this.props.lang;
      this.state = {
         dataInicial: new Date(),
         horaInicial: '00:00',
         horaFinal: '23:59',
         dataFinal: new Date(),
      };
   }

   render() {
      return (
         <Fragment>
            <Container style={{ overflowY: 'auto', maxHeight: '100%', minWidth: '100%', margin: 0, paddingTop: 5 }}>
               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{'Data inicial'}</Label>
                        <DateInput
                           defaultValue={this.state.dataInicial}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataInicial = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{'Hora inicial'}</Label>
                        <TimeInput
                           defaultValue={this.state.horaInicial}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.horaInicial = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{'Data final'}</Label>
                        <DateInput
                           defaultValue={this.state.dataFinal}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataFinal = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{'Hora final'}</Label>
                        <TimeInput
                           defaultValue={this.state.horaFinal}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.horaFinal = value;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <ReactToPrint content={() => this.reportComponentRef}>
                        <PrintContextConsumer>
                           {({ handlePrint }) => (
                              <Row>
                                 <Col style={{ maxWidth: 180 }}>
                                    <Button
                                       text='Gerar'
                                       inProgressText='Gerando...'
                                       onClickAsync={() => {
                                          return new Promise((resolve, reject) => {
                                             if (!this.state.dataInicial) {
                                                showError('Informe a data inicial.');
                                                reject();
                                                return;
                                             }

                                             if (!this.state.dataFinal) {
                                                showError('Informe a data final.');
                                                reject();
                                                return;
                                             }

                                             let query = objectToQueryString({
                                                dataInicial: formatDate(this.state.dataInicial),
                                                horaInicial: this.state.horaInicial,
                                                horaFinal: this.state.horaFinal,
                                                dataFinal: formatDate(this.state.dataFinal),
                                             });

                                             this.props.api
                                                .get('/monitoramento?' + query)
                                                .then((response) => {
                                                   this.setState({
                                                      monitoramentoDoServico: response,
                                                      statusDaTela: STATUS_DA_TELA.Carregado,
                                                   });
                                                   resolve();
                                                })
                                                .catch((e) => {
                                                   reject(e);
                                                });
                                          });
                                       }}
                                    />
                                 </Col>
                                 {this.state.monitoramentoDoServico && (
                                    <Col style={{ maxWidth: 180, paddingLeft: 0 }}>
                                       <Button
                                          onClick={() => {
                                             handlePrint();
                                          }}
                                          text={'Imprimir'}
                                       />
                                    </Col>
                                 )}
                              </Row>
                           )}
                        </PrintContextConsumer>
                     </ReactToPrint>
                  </Col>
               </Row>
               <br />
               {this.state.statusDaTela >= STATUS_DA_TELA.Carregando && (
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        {this.state.statusDaTela === STATUS_DA_TELA.Carregando && (
                           <span>{'Processando informações...'}</span>
                        )}
                     </div>
                  </div>
               )}
               {this.state.statusDaTela === STATUS_DA_TELA.Erro && (
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        <span>{this.state.mensagemDeErro}</span>
                     </div>
                  </div>
               )}
               {this.state.statusDaTela === STATUS_DA_TELA.Carregado && (
                  <div>
                     <div ref={(el) => (this.reportComponentRef = el)}>
                        {this.state.monitoramentoDoServico && this.relatorio()}
                     </div>
                     <br />
                  </div>
               )}
            </Container>
         </Fragment>
      );
   }

   relatorio = () => {
      let item = this.state.monitoramentoDoServico;

      return (
         <React.Fragment>
            <ReportHeader>
               <div style={{ paddingTop: 3, fontSize: 16 }}>
                  <strong>{'Relatório de monitoramento da disponibilidade do sistema'}</strong>
               </div>

               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>
                        {'Período: ' + dateTimeToString(item.dataInicial, this.props.lang) + ' - ' + dateTimeToString(item.dataFinal, this.props.lang)}
                     </span>
                  </div>
               </div>
            </ReportHeader>
            <Row>
               <Col>
                  {this.state.monitoramentoDoServico && (
                     <div>
                    
                        <Filler height={10} />

                        <Row style={{ height: 300 }}>
                           <Col>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Requisições de teste:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>{numberToString(item.totalDeRequisicoes, 0, 3, this.props.lang)}</span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Requisições com sucesso:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>{numberToString(item.totalDeSucessos, 0, 3, this.props.lang)}</span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Requisições com erros:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>{numberToString(item.totalDeErros, 0, 3, this.props.lang)}</span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Percentual de resposta com sucesso:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>{numberToString(item.percentualDeResposta, 0, 2, this.props.lang)}</span>
                                    <span>%</span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Tempo offline:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>{item.tempoOfflinePorExtenso}</span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Tempo médio de resposta:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.tempoMedioDeResposta, 0, 0, this.props.lang) + 'ms'}
                                    </span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Acima de 1 segundo:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.totalDeRequisicoesAcimaDe1, 0, 3, this.props.lang)}
                                    </span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Acima de 3 segundos:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.totalDeRequisicoesAcimaDe3, 0, 3, this.props.lang)}
                                    </span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Acima de 5 segundos:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.totalDeRequisicoesAcimaDe5, 0, 3, this.props.lang)}
                                    </span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Acima de 10 segundos:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.totalDeRequisicoesAcimaDe10, 0, 3, this.props.lang)}
                                    </span>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>Acima de 20 segundos:&nbsp;</span>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span>
                                       {numberToString(item.totalDeRequisicoesAcimaDe20, 0, 3, this.props.lang)}
                                    </span>
                                 </div>
                              </div>
                           </Col>

                           <Col>
                              <div style={{ width: 280, overflow: 'hidden' }}>
                                 <Doughnut
                                    data={{
                                       labels: [
                                          'Online ' +
                                             numberToString(item.percentualDeResposta, 0, 2, this.props.lang) +
                                             '%',
                                          'Offline ' +
                                             numberToString(100 - item.percentualDeResposta, 0, 2, this.props.lang) +
                                             '%',
                                       ],
                                       datasets: [
                                          {
                                             label: 'Disponibilidade',
                                             data: [item.percentualDeResposta, 100 - item.percentualDeResposta],
                                             backgroundColor: ['#2ca308', '#fc6b03'],
                                             borderWidth: 1,
                                          },
                                       ],
                                    }}
                                 />
                              </div>
                           </Col>
                        </Row>
                        
                        <Line />
                     </div>
                  )}
               </Col>
            </Row>
         </React.Fragment>
      );
   };
}
