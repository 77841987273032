import React from 'react';
import { Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { LayoutParams } from '../../config/LayoutParams';
import { Filler } from '../../components/Filler';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import styled from 'styled-components';
import ListaPadrao from '../ListaPadrao';
import {
   buildQueryString,
   dateTimeToString,
   emptyStrIfNull,
   replaceAll,
   retirarAcentos,
   updateState, 
   prepareSearchable      
} from '../../utils/Functions';
import EquipamentoView from './EquipamentoView';
import Select from '../../components/Select';
import { showError } from '../../components/Messages';
import RelatorioDeMovimentacaoView from './RelatorioDeMovimentacaoView';
import SaudeDoServicoView from './SaudeDoServicoView';

let LANG = {};

export default class PainelDeControleView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         abaSelecionada: 'localizarEquipamento',
         percentualInicial: 0,
         percentualFinal: 100,
         filtroEquipamentoNaoLocalizado: null,
         filtroConsumoDeBateria: null,
         filtroGatewaySemComunicacao: null,
         horasSemComunicacaoComEquipamento: 1,
         horasSemComunicacaoComGateway: 1,
         equipamento: null,
         filtroEquipamento: null,
         formHistoricoDeMonitoramento: {},
         formConsumoDeBateria: {},
         formNaoLocalizados: {},
         formSemComunicacao: {},
      };
      LANG = props.lang;
   }

   render() {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <div>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: '5px solid ' + LayoutParams.colors.corSecundaria,
               }}
            >
               <div
                  style={{
                     paddingTop: 0,
                     paddingLeft: 10,
                     paddingRight: 10,
                     height: 45,
                     display: 'flex',
                  }}
               >
                  <div
                     style={{
                        display: 'table-cell',
                        width: '0',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{telaDePainelDeControle.titulo}</span>
                  </div>
               </div>
            </div>
            <Container fluid style={{ padding: 3 }}>
               <Filler height={5} />
               <StyledNav>
                  <Nav variant='tabs' defaultActiveKey={this.state.abaSelecionada} style={{ color: '#444' }}>
                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'localizarEquipamento' })}>
                        <Nav.Link eventKey='localizarEquipamento'>
                           {telaDePainelDeControle.localizarEquipamento}
                        </Nav.Link>
                     </Nav.Item>
                     {/* <Nav.Item onClick={() => this.setState({ abaSelecionada: 'equipamentosNaoLocalizados' })}>
                        <Nav.Link eventKey='equipamentosNaoLocalizados'>
                           {telaDePainelDeControle.equipamentosNaoLocalizados}
                        </Nav.Link>
                     </Nav.Item> */}
                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'consumoDeBateria' })}>
                        <Nav.Link eventKey='consumoDeBateria'>{telaDePainelDeControle.consumoDeBateria}</Nav.Link>
                     </Nav.Item>
                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'gatewaysSemComunicacao' })}>
                        <Nav.Link eventKey='gatewaysSemComunicacao'>
                           {telaDePainelDeControle.gatewaysSemComunicacao}
                        </Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'relatorioDeMovimentacao' })}>
                        <Nav.Link eventKey='relatorioDeMovimentacao'>
                           {telaDePainelDeControle.relatorioDeMovimentacao}
                        </Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'saudeDoServico' })}>
                        <Nav.Link eventKey='saudeDoServico'>{telaDePainelDeControle.saudeDoServico}</Nav.Link>
                     </Nav.Item>

                     {/* <Nav.Item onClick={() => this.setState({ abaSelecionada: 'configuracao' })}>
                        <Nav.Link eventKey='configuracao'>{telaDePainelDeControle.configuracoes}</Nav.Link>
                     </Nav.Item> */}
                  </Nav>
               </StyledNav>
            </Container>

            {this.state.abaSelecionada === 'localizarEquipamento' && this.localizarEquipamento()}
            {this.state.abaSelecionada === 'equipamentosNaoLocalizados' && this.equipamentosNaoLocalizados()}
            {this.state.abaSelecionada === 'consumoDeBateria' && this.consumoDeBateria()}
            {this.state.abaSelecionada === 'gatewaysSemComunicacao' && this.gatewaysSemComunicacao()}
            {this.state.abaSelecionada === 'relatorioDeMovimentacao' && this.relatorioDeMovimentacao()}
            {this.state.abaSelecionada === 'configuracao' && this.configuracao()}
            {this.state.abaSelecionada === 'saudeDoServico' && (
               <SaudeDoServicoView api={this.props.api} lang={this.props.lang} />
            )}
         </div>
      );
   }

   localizarEquipamento = () => {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/historicodemonitoramento'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getTitulosDaTabela={() => {
               return [
                  {
                     
                  },
                  {
                     titulo: telaDePainelDeControle.data,
                     width: '20%',
                     orderby: 'Data',
                  },
                  {
                     titulo: telaDePainelDeControle.predio,
                     width: '30%',
                     orderby: 'AreaAtual.Local.Predio.Nome',
                  },
                  {
                     titulo: telaDePainelDeControle.localAtual,
                     width: '30%',
                     orderby: 'AreaAtual.Local.Nome',
                  },
                  {
                     titulo: telaDePainelDeControle.tag,
                     width: '20%',
                     orderby: 'Tag',
                  },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  dateTimeToString(item.data, this.props.lang),
                  item.areaAtual && item.areaAtual.local && item.areaAtual.local.predio
                     ? item.areaAtual.local.predio.nome
                     : null,
                  item.areaAtual?.local.nome,
                  item.tag?.mac,
               ];
            }}
            estruturaPadrao={true}
            getFormState={() => this.state.formHistoricoDeMonitoramento}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formHistoricoDeMonitoramento = {
                        ...state.formHistoricoDeMonitoramento,
                        ...formState,
                     };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  if (!this.state.equipamento) {
                     showError(telaDePainelDeControle.mensagens.informeOEquipamento);
                     reject();
                  } else {
                     resolve({
                        monitorado: this.state.equipamento.id,
                     });
                  }
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{ maxWidth: 900 }}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.equipamento}</Label>
                              <Select
                                 name={'equipamento'}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.nome}
                                 onSelect={(i) => {
                                    this.setState({ equipamento: i });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <EquipamentoView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/equipamento' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   equipamentosNaoLocalizados = () => {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/equipamento/naolocalizados'}
            api={this.props.api}
            lang={this.props.lang}
            getTitulosDaTabela={() => {
               return [
                  { titulo: LANG.codigo, className: 'codigo', orderby: 'id' },
                  {
                     titulo: telaDePainelDeControle.nomeDoEquipamento,
                     width: '40%',
                     orderby: 'nome',
                  },
                  {
                     titulo: telaDePainelDeControle.numeroDeSerie,
                     width: '15%',
                     orderby: 'numeroDeSerie',
                  },
                  {
                     titulo: telaDePainelDeControle.patrimonio,
                     width: '15%',
                     orderby: 'patrimonio',
                  },
                  {
                     titulo: telaDePainelDeControle.macDaTag,
                     width: '10%',
                     orderby: 'Tag.Mac',
                  },
                  {
                     titulo: telaDePainelDeControle.dataDaUltimaAtualizacao,
                     width: '20%',
                     orderby: 'DataDaUltimaAtualizacao',
                  },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  item.id,
                  item.nome,
                  item.numeroDeSerie,
                  item.patrimonio,
                  item.tag ? item.tag.mac : null,
                  dateTimeToString(item.dataDaUltimaAtualizacao, this.props.lang),
               ];
            }}
            estruturaPadrao={true}
            getFormState={() => this.state.formNaoLocalizados}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formNaoLocalizados = {
                        ...state.formNaoLocalizados,
                        ...formState,
                     };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  resolve({
                     searchable: this.limparTextoDeFiltro(this.state.filtroEquipamentoNaoLocalizado),
                     horasSemComunicacao: this.state.horasSemComunicacaoComEquipamento,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{ maxWidth: 900 }}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.filtro}</Label>
                              <TextInput
                                 onChange={(e) => {
                                    this.setState({
                                       filtroEquipamentoNaoLocalizado: e.target.value,
                                    });
                                 }}
                                 style={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: '#ced4da',
                                 }}
                              />
                           </Form.Group>
                        </Col>
                        <Col style={{ maxWidth: 250 }}>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.horasSemComunicacao}</Label>
                              <IntegerInput
                                 defaultValue={this.state.horasSemComunicacaoComEquipamento}
                                 onChange={(e) => {
                                    this.setState({
                                       horasSemComunicacaoComEquipamento: e.floatValue,
                                    });
                                 }}
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   consumoDeBateria = () => {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/equipamento/consumodebateria'}
            api={this.props.api}
            lang={this.props.lang}
            getTitulosDaTabela={() => {
               return [
                  { titulo: LANG.codigo, className: 'codigo', orderby: 'id' },
                  {
                     titulo: telaDePainelDeControle.nomeDoEquipamento,
                     width: '45%',
                     orderby: 'nome',
                  },
                  {
                     titulo: telaDePainelDeControle.numeroDeSerie,
                     width: '15%',
                     orderby: 'numeroDeSerie',
                  },
                  {
                     titulo: telaDePainelDeControle.patrimonio,
                     width: '15%',
                     orderby: 'patrimonio',
                  },
                  {
                     titulo: telaDePainelDeControle.macDaTag,
                     width: '15%',
                     orderby: 'Tag.Mac',
                  },
                  {
                     titulo: telaDePainelDeControle.bateria,
                     width: '10%',
                     orderby: 'Tag.Bateria',
                  },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  item.id,
                  item.nome,
                  item.numeroDeSerie,
                  item.patrimonio,
                  item.tag ? item.tag.mac : null,
                  emptyStrIfNull(item.percentual) + ' %',
               ];
            }}
            estruturaPadrao={true}
            getFormState={() => this.state.formConsumoDeBateria}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formConsumoDeBateria = {
                        ...state.formConsumoDeBateria,
                        ...formState,
                     };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  resolve({
                     searchable: this.limparTextoDeFiltro(this.state.filtroConsumoDeBateria),
                     percentualInicial: this.state.percentualInicial,
                     percentualFinal: this.state.percentualFinal,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{ maxWidth: 900 }}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.filtro}</Label>
                              <TextInput
                                 onChange={(e) => {
                                    this.setState({
                                       filtroConsumoDeBateria: e.target.value,
                                    });
                                 }}
                                 style={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: '#ced4da',
                                 }}
                              />
                           </Form.Group>
                        </Col>
                        <Col style={{ maxWidth: 180 }}>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>
                                 {telaDePainelDeControle.percentualInicial}
                                 {' (%)'}
                              </Label>
                              <IntegerInput
                                 defaultValue={this.state.percentualInicial}
                                 placeholder={'%'}
                                 onChange={(e) => {
                                    this.setState({ percentualInicial: e.floatValue });
                                 }}
                              />
                           </Form.Group>
                        </Col>
                        <Col style={{ maxWidth: 150 }}>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>
                                 {telaDePainelDeControle.percentualFinal}
                                 {' (%)'}
                              </Label>
                              <IntegerInput
                                 defaultValue={this.state.percentualFinal}
                                 placeholder={'%'}
                                 onChange={(e) => {
                                    this.setState({ percentualFinal: e.floatValue });
                                 }}
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   limparTextoDeFiltro = (texto) => {
      texto = retirarAcentos(texto).toUpperCase();
      var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
      charsToRemove.forEach((c) => {
         texto = replaceAll(texto, c, '');
      });
      return texto;
   };

   gatewaysSemComunicacao = () => {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/gateway/semcomunicacao'}
            api={this.props.api}
            lang={this.props.lang}
            getTitulosDaTabela={() => {
               return [
                  { titulo: LANG.codigo, className: 'codigo', orderby: 'Id' },
                  {
                     titulo: telaDePainelDeControle.mac,
                     width: '15%',
                     orderby: 'Mac',
                  },
                  {
                     titulo: telaDePainelDeControle.patrimonio,
                     width: '15%',
                     orderby: 'Patrimonio',
                  },
                  {
                     titulo: telaDePainelDeControle.predio,
                     width: '20%',
                     orderby: null,
                  },
                  {
                     titulo: telaDePainelDeControle.local,
                     width: '20%',
                     orderby: null,
                  },
                  {
                     titulo: telaDePainelDeControle.mapa,
                     width: '15%',
                     orderby: null,
                  },
                  {
                     titulo: telaDePainelDeControle.dataDaUltimaLeitura,
                     width: '15%',
                     orderby: 'DataDaUltimaLeitura',
                  },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  item.id,
                  item.mac,
                  item.patrimonio,
                  item.nomeDoPredio,
                  item.nomeDoLocal,
                  item.nomeDoMapa,
                  dateTimeToString(item.dataDaUltimaLeitura, this.props.lang),
               ];
            }}
            estruturaPadrao={true}
            getFormState={() => this.state.formSemComunicacao}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formSemComunicacao = {
                        ...state.formSemComunicacao,
                        ...formState,
                     };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  resolve({
                     searchable: this.limparTextoDeFiltro(this.state.filtroGatewaySemComunicacao),
                     horasSemComunicacao: this.state.horasSemComunicacaoComGateway,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{ maxWidth: 900 }}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.filtro}</Label>
                              <TextInput
                                 onChange={(e) => {
                                    this.setState({
                                       filtroGatewaySemComunicacao: e.target.value,
                                    });
                                 }}
                                 style={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: '#ced4da',
                                 }}
                              />
                           </Form.Group>
                        </Col>
                        <Col style={{ maxWidth: 250 }}>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{telaDePainelDeControle.horasSemComunicacao}</Label>
                              <IntegerInput
                                 defaultValue={this.state.horasSemComunicacaoComGateway}
                                 onChange={(e) => {
                                    this.setState({
                                       horasSemComunicacaoComGateway: e.floatValue,
                                    });
                                 }}
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   configuracao = () => {
      return <span>Configuração</span>;
   };

   relatorioDeMovimentacao = () => {
      return <RelatorioDeMovimentacaoView api={this.props.api} lang={this.props.lang} />;
   };
}

const StyledNav = styled.div`
   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
   }
`;
