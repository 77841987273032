import { Container, Nav } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import RelatorioDeMovimentacaoDeEquipamentoEntreLocaisView from './RelatorioDeMovimentacaoDeEquipamentoEntreLocaisView';
import { Filler } from '../../components/Filler';
import RelatorioDeMovimentacaoDeEquipamentoEntrePrediosView from './RelatorioDeMovimentacaoDeEquipamentoEntrePrediosView';
import RelatorioDeMovimentacaoDeEquipamentoView from './RelatorioDeMovimentacaoDeEquipamentoView';
import RelatorioDeMovimentacaoDeEquipamentoPorLocalView from './RelatorioDeMovimentacaoDeEquipamentoPorLocalView';
import RelatorioDeUltimaLocalizacaoView from './RelatorioDeUltimaLocalizacaoView';
import { LayoutParams } from '../../config/LayoutParams';
import styled from 'styled-components';
import { ViewController } from '../../components/ViewController';

let LANG = {};

export default class RelatorioDeMovimentacaoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         abaSelecionada: 'porEquipamento',
         selectedViewNumber: 1
      };
      LANG = props.lang;      
   }

   render() {
      return (
         <Fragment>
            <Container fluid style={{ padding: 3 }}>
               <Filler height={5} />
               <StyledNav>
                  <Nav variant='tabs' defaultActiveKey={this.state.abaSelecionada} style={{ color: '#444' }}>
                     <Nav.Item
                        onClick={() => this.setState({ abaSelecionada: 'porEquipamento', selectedViewNumber: 1 })}
                     >
                        <Nav.Link eventKey='porEquipamento'>{this.props.lang.relatorioDeMovimentacao.movimentacaoPorEquipamento}</Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'entreLocais', selectedViewNumber: 2 })}>
                        <Nav.Link eventKey='entreLocais'>{LANG.relatorioDeMovimentacao.movimentacaoEntreLocais }</Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'entrePredios', selectedViewNumber: 3 })}>
                        <Nav.Link eventKey='entrePredios'>{LANG.relatorioDeMovimentacao.movimentacaoEntrePredios}</Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'porLocal', selectedViewNumber: 4 })}>
                        <Nav.Link eventKey='porLocal'>{LANG.relatorioDeMovimentacao.movimentacaoPorLocal}</Nav.Link>
                     </Nav.Item>

                     <Nav.Item onClick={() => this.setState({ abaSelecionada: 'ultimaLocalizacao', selectedViewNumber: 5 })}>
                        <Nav.Link eventKey='ultimaLocalizacao'>{LANG.relatorioDeMovimentacao.ultimaLocalizacao}</Nav.Link>
                     </Nav.Item>
                  </Nav>
               </StyledNav>
            </Container>

            <ViewController
               selected={this.state.selectedViewNumber}
               v1={<RelatorioDeMovimentacaoDeEquipamentoView lang={this.props.lang} api={this.props.api} />}
               v2={<RelatorioDeMovimentacaoDeEquipamentoEntreLocaisView lang={this.props.lang} api={this.props.api} />}
               v3={<RelatorioDeMovimentacaoDeEquipamentoEntrePrediosView lang={this.props.lang} api={this.props.api} />}
               v4={<RelatorioDeMovimentacaoDeEquipamentoPorLocalView lang={this.props.lang} api={this.props.api} />}
               v5={<RelatorioDeUltimaLocalizacaoView lang={this.props.lang} api={this.props.api} />}
            />
         </Fragment>
      );
   }
}

const StyledNav = styled.div`
   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
   }
`;
