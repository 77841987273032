import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { showError } from '../../components/Messages';
import { LayoutParams } from '../../config/LayoutParams';
import { toEntityReference } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class MapaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'MapaController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeMapa.predio, width: '30%', orderby: 'Predio' },
         { titulo: this.lang.telaDeCadastroDeMapa.nome, width: '45%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDeMapa.situacao, width: '15%', orderby: 'situacao' },
         { titulo: this.lang.telaDeCadastroDeMapa.configuracao, width: '15%' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.predio ? item.predio.nome : null,
         item.nome,
         item.situacao.descricao,
         <div style={{ textAlign: 'center' }}>
            <Link to={'/configurarmapa?id=' + item.id.toString()}>
               <FontAwesomeIcon
                    style={{
                       color: LayoutParams.colors.corSecundaria,
                       fontSize: 20,
                    }}
                    cursor='pointer'
                    icon={faCog}
                    onClick={() => {
                       this.view.setState({
                          id: item.id,
                          nome: item.nome,
                          mostrarHistoricoDeMonitoramento: true,
                       });
                    }}
               />
            </Link>
         </div>,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeMapa.mensagens.informeONomeDoMapa);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            situacao: item.situacao ? item.situacao.id : null,
            predio: toEntityReference(item.predio),
            imagem: toEntityReference(item.imagem),
            locais: item.locais,
            gateways: item.gateways,
            areas: item.areas,
            andar: item.andar,
            altura: item.altura,
            largura: item.largura,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
